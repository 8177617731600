const actions = {
  GET_IMAGE: "GET_IMAGE",
  GET_IMAGE_SUCCESS: "GET_IMAGE_SUCCESS",
  GET_IMAGE_ERROR: "GET_IMAGE_ERROR",

  getImage: (model) => ({
    type: actions.GET_IMAGE,
    payload: { model },
  }),

  CREATE_IMAGE: "CREATE_IMAGE",

  createImage: (body, cbSuccess, cbError) => ({
    type: actions.CREATE_IMAGE,
    payload: { body },
    cbSuccess,
    cbError,
  }),

  DELETE_IMAGE: "DELETE_IMAGE",

  deleteImage: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_IMAGE,
    payload: { id },
    cbSuccess,
    cbError,
  }),
};
export default actions;
