const baseRouter = "/api/";
const apiV1 = baseRouter + "v1/";

export const ApiRouters = {
    LOGIN: apiV1 + "Auth/Portal",
    FORGOT_PASSWORD: apiV1 + "Auth/ForgotPassword",
    CHECK_TOKEN_RESET_PW: apiV1 + "Auth/CheckResetPassword",
    RESET_PASSWORD: apiV1 + "Auth/ResetPassword",

    ACCOUNT: apiV1 + "Account",
    USER_LOGIN_PROFILE: apiV1 + "Account/Profile",
    CHANGE_PASSWORD: apiV1 + "Account/ChangePassword",

    USER: apiV1 + "Users",
    COMMON: apiV1 + "Commons",
    ROLE: apiV1 + "Groups",
    NOTIFICATION: apiV1 + "Notifications",
    TEACHINGSCHEDULE: apiV1 + "TrainingSchedules",
    LIVESTREAM: apiV1 + "Livestreams",
    EVENT: apiV1 + "Events",
    DOCUMENT: apiV1 + "Documents",
    LECTURERS: apiV1 + "Lecturers",
    CATEGORY: apiV1 + "DocumentCategorys",
    MEMBERSHIP: apiV1 + "Memberships",
    STATICPAGE: apiV1 + "StaticPages",
    BANNER: apiV1 + "Banners",
    PAYMENT_DOCUMENTS: apiV1 + "DocumentOrders",
    PAYMENT_ACCOUNTS: apiV1 + "MembershipActivations",
    PACKAGE: apiV1 + "MembershipPackages",
    BASICDOCUMENT: apiV1 + "SettingBasicDocuments",

}