import actions from "./action";

const initState = {
  staticPageList: [],
  loading: false,
  totalItems: 0,
  staticPageDetail: [],
  LandingPageM: [],
  LandingPageS: [],
  LandingPageT: [],
};
export default function staticPageReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_STATIC_PAGE:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_STATIC_PAGE_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        staticPageList: res,
        totalItems: totalItems,
        loading: false,
      };
    }
    case actions.GET_STATIC_PAGE_ERROR:
      return { ...state, loading: false };
    case actions.GET_STATIC_PAGE_DETAIL:
      return { ...state, loading: true };
    case actions.GET_STATIC_PAGE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, staticPageDetail: res, loading: false };
    }
    case actions.GET_STATIC_PAGE_DETAIL_ERROR:
      return { ...state, loading: false };

    case actions.GET_LANDING_PAGE:
      return { ...state };

    case actions.GET_LANDING_PAGE_SUCCESS:
      if (action.payload.url === "landing_page") {
        return { ...state, LandingPageM: action.payload.data };
      } else if (action.payload.url === "business_owners") {
        return { ...state, LandingPageS: action.payload.data };
      } else {
        return { ...state, LandingPageT: action.payload.data };
      }

    case actions.GET_LANDING_PAGE_ERROR: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}
