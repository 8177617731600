const actions = {
    GET_BANNER: "GET_BANNER",
    GET_BANNER_SUCCESS: "GET_BANNER_SUCCESS",
    GET_BANNER_ERROR: "GET_BANNER_ERROR",

    getBanner: () => ({
        type: actions.GET_BANNER,
    }),


    CREATE_BANNER: "CREATE_BANNER",

    createBanner: (body, cbSuccess, cbError) => ({
        type: actions.CREATE_BANNER,
        payload: { body },
        cbSuccess, cbError
    }),
    DELETE_BANNER: "DELETE_BANNER",

    deleteBanner: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_BANNER,
        payload: { id },
        cbSuccess, cbError
    }),
}
export default actions;