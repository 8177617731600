import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getStaticPage = (body) => {
  var url = ApiRouters.STATICPAGE;
  return api.get(url, { params: body });
};

export const getLandingPages = (model) => {
  var url = `${ApiRouters.STATICPAGE}/DynamicLandingPages`;
  return api.get(url, { params: model });
};

export const getStaticPageDetail = (id) => {
  var url = `${ApiRouters.STATICPAGE}/${id}`;
  return api.get(url);
};

export const updateStaticPage = (body) => {
  var url = `${ApiRouters.STATICPAGE}/${body.id}`;
  return api.put(url, body);
};

export const updateLandingPage = (body) => {
  var url = `${ApiRouters.STATICPAGE}/DynamicLandingPages`;
  return api.put(url, body);
};

export const getImageLandingPage = (model) => {
  var url = `${ApiRouters.STATICPAGE}/ImagesForLandingPage`;
  return api.get(url, { params: model });
};
