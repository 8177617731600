import { ApiRouters } from '@iso/utils/apiRouters';
import { api, apiUpload } from '@iso/utils/axios.configs';
import axios from "axios";

export const getLivestreams = body => {
    var url = ApiRouters.LIVESTREAM;
    return api.get(url, { params: body });
};
export const deleteLivestream = id => {
    var url = `${ApiRouters.LIVESTREAM}/${id}`;
    return api.delete(url);
};
export const deleteLivestreamBanner = model => {
    var url = `${ApiRouters.LIVESTREAM}/${id}/Files`;
    return api.delete(url, model.body);
};
export const getLivestreamDetail = id => {
    var url = `${ApiRouters.LIVESTREAM}/${id}`;
    return api.get(url);
};
export const createLivestream = body => {
    var url = ApiRouters.LIVESTREAM;
    return api.post(url, body);
};
export const createLivestreamBanner = model => {
    var url = `${ApiRouters.LIVESTREAM}/${model.id}/Files`;  
    return axios(apiUpload(url, 'post', model.body)); 
};
export const updateLivestream = (id, body) => {
    var url = `${ApiRouters.LIVESTREAM}/${id}`;
    return api.put(url, body);
};