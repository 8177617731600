const actions = {

  GET_EVENTS: 'GET_EVENTS',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',

  GET_EVENT_DETAIL: 'GET_EVENT_DETAIL',
  GET_EVENT_DETAIL_SUCCESS: 'GET_EVENT_DETAIL_SUCCESS',
  GET_EVENT_DETAIL_ERROR: 'GET_EVENT_DETAIL_ERROR',

  CREATE_EVENT: 'CREATE_EVENT',
  CREATE_EVENT_BANNER: 'CREATE_EVENT_BANNER',
  UPDATE_EVENT: 'UPDATE_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_EVENT_BANNER: 'DELETE_EVENT_BANNER',

  //#region CRUD
  getEvents: model => ({
    type: actions.GET_EVENTS,
    payload: { model }
  }),
  deleteEvent: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_EVENT,
    payload: { id },
    cbSuccess, cbError
  }),
  deleteEventBanner: (model, cbError) => ({
    type: actions.DELETE_EVENT_BANNER,
    payload: { model },
    cbError
  }),
  getEventDetail: id => ({
    type: actions.GET_EVENT_DETAIL,
    payload: { id }
  }),
  createEvent: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_EVENT,
    payload: { model },
    cbSuccess, cbError
  }),
  createEventBanner: (model, cbError) => ({
    type: actions.CREATE_EVENT_BANNER,
    payload: { model },
    cbError
  }),
  updateEvent: (id, model, cbSuccess, cbError) => ({
    type: actions.UPDATE_EVENT,
    payload: { id, model },
    cbSuccess, cbError
  }),
  //#endregion
}

export default actions;