import "antd/dist/antd.css";
import { createGlobalStyle } from "styled-components";
import { font, palette } from "styled-theme";

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    &-content {
      border-radius: 2px;
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        font-size: 13px;
        margin-left: 0 !important;
        span {
          font-weight: 500;
        }
        &:not(:last-child) {
          margin-right: 16px;
        }
        &:first-child {
          border: 0;
          span {
            text-decoration: underline;
            color: ${palette("color", 14)} !important;
            &:hover {
              color: red !important;
            }
          }
        }
        &:last-child {
          padding: 0px 12px;
          height: auto;
          background: ${palette("color", 19)} !important;
          border-color: ${palette("color", 18)} !important;
          color: ${palette("color", 18)} !important;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      &-header {
        padding: 16px;
      }
      .ant-modal-content {
        .ant-modal-body {
          padding: 16px;
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font("primary", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${"" /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ant-drawer-content-wrapper {
    .ant-drawer-content {
      .tiens-filter {
        border-bottom: 1px solid ${palette("grayscale", 12)};
      }
      .tiens-row {
        margin-bottom: 24px;
      }
      .tiens-list-select {
        width: 100%;
        .ant-select-selection-placeholder {
          color: ${palette("color", 17)};
          display: flex;
          align-items: center;
        }
      }
      .tiens-list-label {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 500;
      }
      .tiens-list-picker {
        width: 100%;
        border-radius: 2px;
        height: 32px;
        input::placeholder {
        color: ${palette("color", 17)};
        }
      }
      .tiens-btn-actions {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-search {
          color: ${palette("color", 18)} !important;
          background: ${palette("color", 19)} !important;
          border-color: ${palette("color", 18)} !important;
          font-weight: 500;
          width: 100%;
        }
      }
      .wrapper-clear {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 16px;
      }
      button {
        padding: 0 18px;
        height: 35px;
        &:hover, &:focus {
          opacity: 0.8;
        }
        &.btn-clear {
          color: ${palette("danger", 1)};
          border: 0;
          span {
            text-decoration: underline;
          }
        }
      }
      .tiens-list-dropdown {
        border: 1px solid ${palette("grayscale", 12)};
        padding: 5px 12px 5px 8px;
        width: 100%;
        border-radius: 2px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${palette("color", 14)};
        span {
          margin-left: 18px;
        }
      }
      .ant-drawer-header, .ant-drawer-body {
        padding: 16px;
      }
  }
}
`;

export default GlobalStyles;
