import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import { RootPaths } from "@iso/constants/Models";

export const getListMembersService = (model) => {
  var url = ApiRouters.MEMBERSHIP;
  return api.get(url, { params: model });
};

export const getMemberDetailService = (id) => {
  var url = `${ApiRouters.MEMBERSHIP}/${id}`;
  return api.get(url);
};

export const createMemberService = (body) => {
  var url = ApiRouters.MEMBERSHIP;
  return api.post(url, body);
};

export const deleteMemberService = (id) => {
  var url = `${ApiRouters.MEMBERSHIP}/${id}`;
  return api.delete(url);
};

export const updateMemberService = (model) => {
  var url = `${ApiRouters.MEMBERSHIP}/${model.id}`;
  return api.put(url, model);
};

export const getMemberPurchasedDocuments = (body) => {
  var url = `${ApiRouters.MEMBERSHIP}/${body.id}/PurchasedDocuments`;
  return api.get(url);
};

export const getMemberExtension = (body) => {
  var url = `${ApiRouters.MEMBERSHIP}/${body.id}/ActivationHistories`;
  return api.get(url);
};
export const postActivate = (model) => {
  var url = `${ApiRouters.MEMBERSHIP}/${model.id}/ActivateOrRenew`;
  return api.post(url, model);
};

export const getRegisteredActivities = (id, model) => {
  var url = getPathNameService(id, model);
  return api.get(url, { params: model });
};

export const getPathNameService = (id, model) => {
  let url = "";
  switch (model.PathName) {
    case RootPaths.TeachingSchedules:
      url = url + `${ApiRouters.MEMBERSHIP}/${id}/TrainingSchedules`;
      break;
    case RootPaths.Livestreams:
      url = url + `${ApiRouters.MEMBERSHIP}/${id}/Livestreams`;
      break;
    case RootPaths.Events:
      url = url + `${ApiRouters.MEMBERSHIP}/${id}/Events`;
      break;

    default:
      break;
  }
  return url;
};
export const resetPasswordMember = (body) => {
  var url = `${ApiRouters.MEMBERSHIP}/${body.id}/Password`;
  return api.put(url, body);
};
export const postNotification = (model) => {
  var url = ApiRouters.NOTIFICATION;
  return api.post(url, model);
};
export const getMemberTreeLevel = (code) => {
  var url = `${ApiRouters.MEMBERSHIP}/${code}/Children`;
  return api.get(url);
};
export const getListHistoryLevel = (model) => {
  var url = `${ApiRouters.MEMBERSHIP}/${model.id}/LevelHistories`;
  return api.get(url, { params: model });
};
export const getPackageSelectList = () => {
  var url = `${ApiRouters.PACKAGE}/SelectList`;
  return api.get(url);
};

export const getLandingPageList = (id) => {
  var url = `${ApiRouters.MEMBERSHIP}/${id}/GetLinkLandingpage`;
  return api.get(url);
};

export const changeStatusLandingPage = (model) => {
  var url = `${ApiRouters.MEMBERSHIP}/LandingpageStatus`;
  return api.put(url, model);
};
