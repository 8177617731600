import { api } from "@iso/utils/axios.configs";
import { ApiRouters } from "@iso/utils/apiRouters";

export const getImage = (model) => {
  var url = `${ApiRouters.STATICPAGE}/ImagesForLandingPage`;
  return api.get(url, { params: model });
};

export const createImage = (body) => {
  var url = `${ApiRouters.MEMBERSHIP}/${body.get("Id")}/CreateImageLandingPage`;
  return api.post(url, body);
};

export const deleteImage = (id) => {
  var url = `${ApiRouters.MEMBERSHIP}/DeleteImageLandingPage/${id}`;
  return api.delete(url);
};
