import accountSaga from "@iso/redux/account/saga";
import authSaga from "@iso/redux/auth/saga";
import bannerSaga from "@iso/redux/banner/saga";
import imageSaga from "@iso/redux/image/saga";
import categoriesSaga from "@iso/redux/categories/saga";
import commonSaga from "@iso/redux/common/saga";
import documentSaga from "@iso/redux/document/saga";
import eventSaga from "@iso/redux/event/saga";
import lecturersSaga from "@iso/redux/lecturers/saga";
import livestreamSaga from "@iso/redux/livestream/saga";
import membersSaga from "@iso/redux/members/saga";
import notificationSaga from "@iso/redux/notifications/saga";
import paymentSagas from "@iso/redux/payments/sagas";
import roleSaga from "@iso/redux/roles/saga";
import staticPageSaga from "@iso/redux/staticPage/saga";
import teachingScheduleSaga from "@iso/redux/teachingSchedule/saga";
import userSaga from "@iso/redux/users/saga";
import packageSaga from "@iso/redux/package/saga";
import basicDocumentSaga from "@iso/redux/basicDocument/saga";
import { all } from "redux-saga/effects";

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    accountSaga(),
    commonSaga(),
    userSaga(),
    roleSaga(),
    notificationSaga(),
    teachingScheduleSaga(),
    livestreamSaga(),
    eventSaga(),
    documentSaga(),
    lecturersSaga(),
    categoriesSaga(),
    membersSaga(),
    staticPageSaga(),
    bannerSaga(),
    imageSaga(),
    paymentSagas(),
    packageSaga(),
    basicDocumentSaga(),
  ]);
}
