import actions from "./actions";
const initState = {
  listImage: [],
  loading: false,
};
export default function imageReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_IMAGE:
      return { ...state, loading: true };

    case actions.GET_IMAGE_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, listImage: res, loading: false };
    }
    case actions.GET_IMAGE_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
