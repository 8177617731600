import actions from "./actions";

const initState = {
  members: {
    list: [],
    loading: false,
    totalItems: 0,
  },
  memberDetail: {
    detail: {},
    loading: false,
  },
  registeredActivities: {
    list: [],
    spinLoading: false,
    totalItems: 0,
  },
  listMemberPurchased: [],
  spinLoading: false,
  totalItems: 0,
  listMemberExtension: [],
  memberTreeLevel: [],

  historyMemberLevel: {
    list: [],
    loading: false,
    totalItems: 0,
  },
  packageSelectList: [],
  landingPageList: [],
};

export default function memberReducer(state = initState, action) {
  switch (action.type) {
    //#region LIST
    case actions.GET_MEMBERS:
      return { ...state, members: { ...state.members, loading: true } };

    case actions.GET_MEMBERS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        members: {
          ...state.members,
          list: res,
          totalItems: totalItems,
          loading: false,
        },
      };
    }

    case actions.GET_MEMBERS_ERROR:
      return {
        ...state,
        members: { ...state.members, list: [], totalItems: 0, loading: false },
      };
    //#endregion

    //#region DETAIL
    case actions.GET_MEMBER_DETAIL:
      return {
        ...state,
        memberDetail: { ...state.memberDetail, loading: true },
      };

    case actions.GET_MEMBER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return {
        ...state,
        memberDetail: { ...state.memberDetail, detail: res, loading: false },
      };
    }

    case actions.GET_MEMBER_DETAIL_ERROR:
      return {
        ...state,
        memberDetail: { ...state.memberDetail, detail: {}, loading: false },
      };
    //#endregion

    case actions.GET_MEMBER_PURCHASED_DOCUMENT:
      return { ...state, spinLoading: true };

    case actions.GET_MEMBER_PURCHASED_DOCUMENT_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        listMemberPurchased: res,
        totalItems: totalItems,
        spinLoading: false,
      };
    }
    case actions.GET_MEMBER_PURCHASED_DOCUMENT_ERROR:
      return { ...state, spinLoading: false };

    case actions.GET_MEMBER_EXTENSION:
      return { ...state, loading: true };
    case actions.GET_MEMBER_EXTENSION_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        listMemberExtension: res,
        totalItems: totalItems,
        spinLoading: false,
      };
    }
    case actions.GET_MEMBER_EXTENSION_ERROR:
      return { ...state, spinLoading: false };

    case actions.GET_REGISTERED_ACTIVITIES:
      return {
        ...state,
        loading: true,
        registeredActivities: {
          list: [],
          loading: true,
          totalItems: 0,
        },
      };
    case actions.GET_REGISTERED_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        registeredActivities: {
          ...state.registeredActivities,
          list: action.payload?.data ? action.payload?.data : [],
          totalItems: action.payload?.totalItems,
          loading: false,
        },
      };
    }
    case actions.GET_REGISTERED_ACTIVITIES_ERROR:
      return {
        ...state,
        registeredActivities: { ...state.registeredActivities, loading: false },
      };

    case actions.GET_MEMBER_TREE_LEVEL:
      return { ...state, loading: true };
    case actions.GET_MEMBER_TREE_LEVEL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, memberTreeLevel: res, loading: false };
    }
    case actions.GET_MEMBER_TREE_LEVEL_ERROR:
      return { ...state, loading: false };

    case actions.GET_MEMBER_HISTORY_LEVEL:
      return {
        ...state,
        historyMemberLevel: { ...state.historyMemberLevel, loading: true },
      };
    case actions.GET_MEMBER_HISTORY_LEVEL_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        historyMemberLevel: {
          ...state.historyMemberLevel,
          list: res,
          totalItems: totalItems,
          loading: false,
        },
      };
    }
    case actions.GET_MEMBER_HISTORY_LEVEL_ERROR:
      return {
        ...state,
        historyMemberLevel: {
          ...state.historyMemberLevel,
          list: [],
          totalItems: 0,
          loading: false,
        },
      };

    case actions.GET_PACKAGE_SELECT_LIST:
      return { ...state };

    case actions.GET_PACKAGE_SELECT_LIST_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, packageSelectList: res };
    }

    case actions.GET_LANDINGPAGE_LIST:
      return { ...state };

    case actions.GET_LANDINGPAGE_LIST_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, landingPageList: res };
    }

    case actions.GET_PACKAGE_SELECT_LIST:
      return { ...state };

    case actions.GET_PACKAGE_SELECT_LIST_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, packageSelectList: res };
    }

    case actions.CHANGE_STATUS_LANDINGPAGE:
      return { ...state };

    case actions.CHANGE_STATUS_LANDINGPAGE_SUCCESS: {
      let { data } = action.payload;

      const newLandingPageList = state.landingPageList.map((page) => {
        if (page.id === data.id) {
          return { ...page, isActive: data.isActive };
        } else {
          return { ...page };
        }
      });

      return { ...state, landingPageList: newLandingPageList };
    }

    default:
      return state;
  }
}
