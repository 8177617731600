import * as lecturerService from "@iso/services/lecturer";
import * as moment from 'moment';
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD
export function* getLecturerListSaga() {
  yield takeEvery(actions.GET_LECTURER_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(lecturerService.getLecturerListService, model);
      if (response.data.success === true) {
        const { data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_LECTURER_LIST_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_LECTURER_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* getLecturerDetailSaga() {
  yield takeEvery(
    actions.GET_LECTURER_DETAIL,
    function* ({ payload, _cbError }) {
      const { id } = payload;
      try {
        var response = yield call(lecturerService.getLecturerDetailService, id);
        if (response.data.success === true) {
          const { data } = response.data;
          yield put({
            type: actions.GET_LECTURER_DETAIL_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.GET_LECTURER_DETAIL_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* createLecturerSaga() {
  yield takeEvery(
    actions.CREATE_LECTURER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        var response = yield call(lecturerService.createLecturerService, body);
        if (response.data.success === true) {
          const { id } = response.data.data;
          yield _cbSuccess(id);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* createLecturerAvatarSaga() {
  yield takeEvery(
    actions.CREATE_LECTURER_AVATAR,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          lecturerService.createLecturerAvatarService,
          model
        );
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* deleteLecturerSaga() {
  yield takeEvery(
    actions.DELETE_LECTURER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { id } = payload;
      try {
        var response = yield call(lecturerService.deleteLecturerService, id);
        if (response.data.success === true) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* updateLecturerSaga() {
  yield takeEvery(
    actions.UPDATE_LECTURER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(lecturerService.updateLecturerService, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* getLecturerSelectboxSaga() {
  yield takeEvery(
    actions.GET_LECTURER_SELECTBOX,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          lecturerService.getLecturerSelectboxService,
          model
        );
        if (response.data.success === true) {
          const { data } = response.data;
          yield put({
            type: actions.GET_LECTURER_SELECTBOX_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.GET_LECTURER_SELECTBOX_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* getLecturerRevenueSaga() {
  yield takeEvery(
    actions.GET_LECTURER_REVENUE,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          lecturerService.getLecturerRevenueService,
          model
        );
        if (response.data.success === true) {
          const { data } = response.data;
          yield put({
            type: actions.GET_LECTURER_REVENUE_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          _cbError(message);
        }
      } catch (e) {
        yield put({
          type: actions.GET_LECTURER_REVENUE_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* getLecturerPayment() {
  yield takeEvery(actions.GET_LECTURER_PAYMENT, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(lecturerService.getLecturerPayment, model);
      if (response.data.success === true) {
        const { data } = response.data;
        yield put({
          type: actions.GET_LECTURER_PAYMENT_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_LECTURER_PAYMENT_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}
export function* createLecturerPayment() {
  yield takeEvery(actions.CREATE_LECTURER_PAYMENT, function* ({ payload, cbSuccess, cbError }) {
    const { id, model } = payload;
    try {
      var response = yield call(lecturerService.createLecturerPayment, id, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* exportLecturerPayment() {
  yield takeEvery(actions.GET_LECTURER_EXPORT_PAYMENT, function* ({ payload }) {
    const { model } = payload
    try {
      var response = yield call(lecturerService.exportLecturerPayment, model);
      if (response.data) {
        let date = moment(new Date()).format("DD_MM_YYYY");
        const fileName = `LecturerPayment_${date}.xlsx`;
        yield exportSuccess(fileName, response.data);
      }
    } catch (e) {
      yield put({ type: actions.EXPORT_ERROR })
    }
  })
}
export function* exportLecturerRevenue() {
  yield takeEvery(actions.GET_LECTURER_EXPORT_REVENUE, function* ({ payload }) {
    const { model } = payload
    try {
      var response = yield call(lecturerService.exportLecturerRevenue, model);
      if (response.data) {
        let date = moment(new Date()).format("DD_MM_YYYY");
        const fileName = `LecturerRevenue_${date}.xlsx`;
        yield exportSuccess(fileName, response.data);
      }
    } catch (e) {
      yield put({ type: actions.EXPORT_ERROR })
    }
  })
}

//#endregion
function* exportSuccess(fileName, data) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  yield put({ type: actions.EXPORT_ERROR });
}
export default function* rootSaga() {
  yield all([
    fork(getLecturerListSaga),
    fork(getLecturerDetailSaga),
    fork(getLecturerSelectboxSaga),
    fork(createLecturerSaga),
    fork(createLecturerAvatarSaga),
    fork(deleteLecturerSaga),
    fork(updateLecturerSaga),
    fork(getLecturerRevenueSaga),
    fork(getLecturerPayment),
    fork(createLecturerPayment),
    fork(exportLecturerPayment),
    fork(exportLecturerRevenue)
  ]);
}
