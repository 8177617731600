import actions from './actions';
const initState = {
    basicDocument: [],
    basicDocumentChild: [],
    loading: false,
};
export default function basicDocumentReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_BASIC_DOCUMENT:
            return { ...state, loading: true };
        case actions.GET_BASIC_DOCUMENT_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, basicDocument: res, loading: false };
        }
        case actions.GET_BASIC_DOCUMENT_ERROR:
            return { ...state, loading: false }
        default:
            return state;
    }
}