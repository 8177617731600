import actions from "./actions";

const initState = {
  lecturers: {
    list: [],
    loading: false,
    totalItems: 0,
  },
  lecturerSelect: [],
  lecturerDetail: {
    detail: {},
    loading: false,
  },
  revenues: {
    list: [],
    loading: false,
    totalItems: 0,
    totalRevenue: 0,
    monthlyRevenue: 0,
  },
  lecturerPayment: {
    list: [],
    loading: false,
    totalItems: 0,
    totalPayment: 0,
    monthlyPayment: 0
  },
};

export default function lecturerReducer(state = initState, action) {
  switch (action.type) {
    //#region LIST
    case actions.GET_LECTURER_LIST:
      return { ...state, lecturers: { ...state.lecturers, loading: true } };

    case actions.GET_LECTURER_LIST_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        lecturers: {
          ...state.lecturers,
          list: res,
          totalItems: totalItems,
          loading: false,
        },
      };
    }

    case actions.GET_LECTURER_LIST_ERROR:
      return {
        ...state,
        lecturers: {
          ...state.lecturers,
          list: [],
          totalItems: 0,
          loading: false,
        },
      };

    case actions.GET_LECTURER_SELECTBOX:
      return { ...state };
    case actions.GET_LECTURER_SELECTBOX_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, lecturerSelect: res };
    }
    case actions.GET_LECTURER_SELECTBOX_ERROR:
      return { ...state, lecturerSelect: [] };
    //#endregion
    //#region DETAIL
    case actions.GET_LECTURER_DETAIL:
      return {
        ...state,
        lecturerDetail: { ...state.lecturerDetail, loading: true },
      };
    case actions.GET_LECTURER_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return {
        ...state,
        lecturerDetail: {
          ...state.lecturerDetail,
          detail: res,
          loading: false,
        },
      };
    }
    case actions.GET_LECTURER_DETAIL_ERROR:
      return {
        ...state,
        lecturerDetail: { ...state.lecturerDetail, detail: {}, loading: false },
      };
    //#endregion
    //#region REVENUE
    case actions.GET_LECTURER_REVENUE:
      return { ...state, revenues: { ...state.revenues, loading: true } };
    case actions.GET_LECTURER_REVENUE_SUCCESS: {
      let { data } = action.payload;
      const { monthRevenue, totalRevenue, revenues } = data;
      let res = revenues?.data?.length ? revenues.data : [];
      return {
        ...state,
        revenues: {
          ...state.revenues,
          list: res,
          loading: false,
          totalItems: revenues?.total,
          monthlyRevenue: monthRevenue,
          totalRevenue: totalRevenue,
        },
      };
    }
    case actions.GET_LECTURER_REVENUE_ERROR:
      return {
        ...state,
        revenues: {
          ...state.revenues,
          list: [],
          loading: false,
          totalItems: 0,
          monthlyRevenue: 0,
          totalRevenue: 0,
        },
      };
    //#endregion
    //#region PAYMENT
    case actions.GET_LECTURER_PAYMENT:
      return { ...state, lecturerPayment: { ...state.lecturerPayment, loading: true } };
    case actions.GET_LECTURER_PAYMENT_SUCCESS:
      {
        let { data } = action.payload;
        let res = data ? data : [];
        return {
          ...state,
          lecturerPayment: {
            ...state.lecturerPayment,
            list: res.revenuePayments.data,
            totalItems: res.revenuePayments.total,
            totalPayment: res.totalRevenuePayment,
            monthlyPayment: res.monthRevenuePayment,
            loading: false,
          },
        };
      }
    case actions.GET_LECTURER_PAYMENT_ERROR:
      return {
        ...state,
        lecturerPayment: {
          ...state.lecturerPayment,
          list: [],
          totalItems: 0,
          loading: false,
          totalPayment: 0,
          monthlyPayment: 0,
        },
      };
    //#endregion
    default:
      return state;
  }
}
