import * as eventService from '@iso/services/event';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region CRUD
export function* getEvents() {
  yield takeEvery(actions.GET_EVENTS, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(eventService.getEvents, model);
      if (response.data.success === true) {
        var data = response.data.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_EVENTS_SUCCESS,
          payload: { data, totalItems }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_EVENTS_ERROR,
      })
    }
  })
}

export function* deleteEvent() {
  yield takeEvery(actions.DELETE_EVENT, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(eventService.deleteEvent, id);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* getEventDetail() {
  yield takeEvery(actions.GET_EVENT_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(eventService.getEventDetail, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_EVENT_DETAIL_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_EVENT_DETAIL_ERROR,
      })
    }
  })
}

export function* createEvent() {
  yield takeEvery(actions.CREATE_EVENT, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(eventService.createEvent, model);
      if (response.data.success === true) {
        const { id } = response.data.data;
        yield cbSuccess(id);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* createEventBanner() {
  yield takeEvery(actions.CREATE_EVENT_BANNER, function* ({ payload, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(eventService.createEventBanner, model);
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* updateEvent() {
  yield takeEvery(actions.UPDATE_EVENT, function* ({ payload, cbSuccess, cbError }) {
    const { id, model } = payload;
    try {
      var response = yield call(eventService.updateEvent, id, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getEvents),
    fork(deleteEvent),
    fork(getEventDetail),
    fork(createEvent),
    fork(createEventBanner),
    fork(updateEvent),
  ])
}