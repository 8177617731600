import actions from './actions';

const initState = {
  categorySelect: [],
  categoryList: [],
  loading: false,
  totalItems: 0,
  categoryDetail: [],
};

export default function categoryReducer(state = initState, action) {
  switch (action.type) {
    case actions.RESET_CATEGORY_SELECTBOX:
      return { ...state, categorySelect: [] };

    //#region CRUD
    case actions.GET_CATEGORY_SELECTBOX:
      return { ...state };
    case actions.GET_CATEGORY_SELECTBOX_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, categorySelect: res };
    }
    case actions.GET_CATEGORY_SELECTBOX_ERROR:
      return { ...state, categorySelect: [] };

    //GET CATEGORIES

    case actions.GET_CATEGORY:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_CATEGORY_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];

      return { ...state, categoryList: res, totalItems: totalItems, loading: false };
    }
    case actions.GET_CATEGORY_ERROR:
      return { ...state, loading: false }
    //#endregion

    // GET CATEGORIES detail
    case actions.GET_CATEGORY_DETAIL:
      return { ...state, loading: true };

    case actions.GET_CATEGORY_DETAIL_SUCCESS:
      {
        let { data } = action.payload;
        let res = data ? data : [];
        return { ...state, categoryDetail: res, loading: false };
      }
    default:
      return state;

  }
}
