import actions from "./actions";

const initState = {
  documentsOrdered: [],
  documentOrderedDetail: {},
  accountsActivated: [],
  accountActivatedDetail: {},
  loading: false,
  totalItems: 0,
  totalRevenue: 0,
};

export default function paymentReducer(state = initState, action) {
  switch (action.type) {
    //#region LIST
    case actions.GET_DOCUMENTS_ORDERED:
      return { ...state, loading: true };

    case actions.GET_DOCUMENTS_ORDERED_SUCCESS: {
      let { orders, totalRevenue } = action.payload;
      let res = orders?.data?.length ? orders?.data : [];
      return {
        ...state,
        documentsOrdered: res,
        totalItems: orders?.total ? orders.total : 0,
        totalRevenue: totalRevenue,
        loading: false,
      };
    }

    case actions.GET_DOCUMENTS_ORDERED_ERROR:
      return {
        ...state,
        documentsOrdered: [],
        totalItems: 0,
        totalRevenue: 0,
        loading: false,
      };

    case actions.GET_ACCOUNTS_ACTIVATED:
      return { ...state, loading: true };

    case actions.GET_ACCOUNTS_ACTIVATED_SUCCESS: {
      let { activations, totalRevenue } = action.payload;
      let res = activations?.data?.length ? activations?.data : [];
      return {
        ...state,
        accountsActivated: res,
        totalItems: activations?.total ? activations.total : 0,
        totalRevenue: totalRevenue,
        loading: false,
      };
    }

    case actions.GET_ACCOUNTS_ACTIVATED_ERROR:
      return {
        ...state,
        accountsActivated: [],
        totalItems: 0,
        totalRevenue: 0,
        loading: false,
      };
    //#endregion

    //#region DETAIL
    case actions.GET_DOCUMENT_ORDERED_DETAIL:
      return { ...state, loading: true };

    case actions.GET_DOCUMENT_ORDERED_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data?.id ? data : {};
      return {
        ...state,
        documentOrderedDetail: res,
        loading: false,
      };
    }

    case actions.GET_DOCUMENT_ORDERED_DETAIL_ERROR:
      return {
        ...state,
        documentOrderedDetail: {},
        loading: false,
      };

    case actions.GET_ACCOUNT_ACTIVATED_DETAIL:
      return { ...state, loading: true };

    case actions.GET_ACCOUNT_ACTIVATED_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data?.id ? data : {};
      return {
        ...state,
        accountActivatedDetail: res,
        loading: false,
      };
    }

    case actions.GET_ACCOUNT_ACTIVATED_DETAIL_ERROR:
      return {
        ...state,
        accountActivatedDetail: {},
        loading: false,
      };
    //#endregion

    default:
      return state;
  }
}
