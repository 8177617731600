const actions = {

  GET_LECTURER_LIST: 'GET_LECTURER_LIST',
  GET_LECTURER_LIST_SUCCESS: 'GET_LECTURER_LIST_SUCCESS',
  GET_LECTURER_LIST_ERROR: 'GET_LECTURER_LIST_ERROR',

  getLecturerList: (model, _cbError) => ({
    type: actions.GET_LECTURER_LIST,
    payload: { model },
    _cbError,
  }),

  GET_LECTURER_DETAIL: "GET_LECTURER_DETAIL",
  GET_LECTURER_DETAIL_SUCCESS: "GET_LECTURER_DETAIL_SUCCESS",
  GET_LECTURER_DETAIL_ERROR: "GET_LECTURER_DETAIL_ERROR",

  getLecturerDetail: (id, _cbError) => ({
    type: actions.GET_LECTURER_DETAIL,
    payload: { id },
    _cbError
  }),

  //#region Select box
  GET_LECTURER_SELECTBOX: "GET_LECTURER_SELECTBOX",
  GET_LECTURER_SELECTBOX_SUCCESS: "GET_LECTURER_SELECTBOX_SUCCESS",
  GET_LECTURER_SELECTBOX_ERROR: "GET_LECTURER_SELECTBOX_ERROR",

  getLecturerSelectbox: (model, _cbError) => ({
    type: actions.GET_LECTURER_SELECTBOX,
    payload: { model },
    _cbError,
  }),

  CREATE_LECTURER: "CREATE_LECTURER",
  createLecturer: (body, _cbSuccess, _cbError) => ({
    type: actions.CREATE_LECTURER,
    payload: { body },
    _cbSuccess,
    _cbError
  }),

  CREATE_LECTURER_AVATAR: "CREATE_LECTURER_AVATAR",
  createLecturerAvatar: (model, _cbError) => ({
    type: actions.CREATE_LECTURER_AVATAR,
    payload: { model },
    _cbError
  }),

  DELETE_LECTURER: "DELETE_LECTURER",
  deleteLecturer: (id, _cbSuccess, _cbError) => ({
    type: actions.DELETE_LECTURER,
    payload: { id },
    _cbSuccess,
    _cbError
  }),

  UPDATE_LECTURER: "UPDATE_LECTURER",
  updateLecturer: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_LECTURER,
    payload: { model },
    _cbSuccess,
    _cbError
  }),

  GET_LECTURER_REVENUE: "GET_LECTURER_REVENUE",
  GET_LECTURER_REVENUE_SUCCESS: "GET_LECTURER_REVENUE_SUCCESS",
  GET_LECTURER_REVENUE_ERROR: "GET_LECTURER_REVENUE_ERROR",
  getLecturerRevenue: (model, _cbError) => ({
    type: actions.GET_LECTURER_REVENUE,
    payload: { model },
    _cbError,
  }),
  GET_LECTURER_PAYMENT: "GET_LECTURER_PAYMENT",
  GET_LECTURER_PAYMENT_SUCCESS: "GET_LECTURER_PAYMENT_SUCCESS",
  GET_LECTURER_PAYMENT_ERROR: "GET_LECTURER_PAYMENT_ERROR",

  getLecturePayment: (model, _cbError) => ({
    type: actions.GET_LECTURER_PAYMENT,
    payload: { model },
    _cbError
  }),

  CREATE_LECTURER_PAYMENT: "CREATE_LECTURER_PAYMENT",

  createLecturerPayment: (id, model, cbSuccess, cbError) => ({
    type: actions.CREATE_LECTURER_PAYMENT,
    payload: { id, model },
    cbSuccess, cbError
  }),

  GET_LECTURER_EXPORT_PAYMENT: "GET_LECTURER_EXPORT_PAYMENT",
  EXPORT_ERROR: "EXPORT_ERROR",
  getExportLecturerPayment: (model, cbSuccess, cbError) => ({
    type: actions.GET_LECTURER_EXPORT_PAYMENT,
    payload: { model },
    cbSuccess, cbError
  }),
  GET_LECTURER_EXPORT_REVENUE: "GET_LECTURER_EXPORT_REVENUE",
  getExportLecturerRevenue: (model) => ({
    type: actions.GET_LECTURER_EXPORT_REVENUE,
    payload: { model },
  })
  //#endregion
}

export default actions;