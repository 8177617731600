import * as categoryService from '@iso/services/category';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

export function* getCategoriesSaga() {
  yield takeEvery(actions.GET_CATEGORY, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(categoryService.getCategoryService, model);
      if (response.data.success === true) {
        var data = response.data.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_CATEGORY_SUCCESS,
          payload: { data, totalItems }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CATEGORY_ERROR,
      })
    }
  })
}
export function* createCategory() {
  yield takeEvery(actions.CREATE_CATEGORY, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(categoryService.createCategoryService, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* deleteCategory() {
  yield takeEvery(actions.DELETE_CATEGORY, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(categoryService.deleteCategoryService, id);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* getCategoryDetail() {
  yield takeEvery(actions.GET_CATEGORY_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(categoryService.getCategoryDetailService, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_CATEGORY_DETAIL_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_CATEGORY_DETAIL_ERROR,
      })
    }
  })
}
export function* updateCategory() {
  yield takeEvery(actions.UPDATE_CATEGORY, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(categoryService.updateCategoryService, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* getCategorySelectboxSaga() {
  yield takeEvery(actions.GET_CATEGORY_SELECTBOX, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(categoryService.getCategorySelectboxService, model);
      if (response.data.success === true) {
        const { data } = response.data;
        yield put({
          type: actions.GET_CATEGORY_SELECTBOX_SUCCESS,
          payload: { data }
        });
      } else {
        let { message } = response.data;
        _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_CATEGORY_SELECTBOX_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getCategorySelectboxSaga),
    fork(getCategoriesSaga),
    fork(createCategory),
    fork(deleteCategory),
    fork(getCategoryDetail),
    fork(updateCategory)
  ])
}