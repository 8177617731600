import * as staticPageService from "@iso/services/staticPage";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./action";

export function* getStaticPage() {
  yield takeEvery(actions.GET_STATIC_PAGE, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(staticPageService.getStaticPage, model);
      if (response.data.success === true) {
        var data = response.data.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_STATIC_PAGE_SUCCESS,
          payload: { data, totalItems },
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_STATIC_PAGE_ERROR,
      });
    }
  });
}

export function* getLandingPage() {
  yield takeEvery(actions.GET_LANDING_PAGE, function* ({ payload }) {
    const { model } = payload;

    try {
      var response = yield call(staticPageService.getLandingPages, model);
      if (response.data.success === true) {
        var data = response.data.data.entity;
        var url = response.data.data.url;
        yield put({
          type: actions.GET_LANDING_PAGE_SUCCESS,
          payload: { data, url },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_STATIC_PAGE_ERROR });
    }
  });
}

export function* getImgLandingPage() {
  yield takeEvery(actions.GET_IMG_LANDING_PAGE, function* ({ payload }) {
    const { model } = payload;

    try {
      var response = yield call(staticPageService.getImageLandingPage, model);
      if (response.data.success === true) {
        var data = response.data.data.entity;
        var url = response.data.data.url;
        yield put({
          type: actions.GET_IMG_LANDING_PAGE_SUCCESS,
          payload: { data, url },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_STATIC_PAGE_ERROR });
    }
  });
}

export function* getStaticPageDetail() {
  yield takeEvery(actions.GET_STATIC_PAGE_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(staticPageService.getStaticPageDetail, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_STATIC_PAGE_DETAIL_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_STATIC_PAGE_ERROR,
      });
    }
  });
}
export function* updateStaticPage() {
  yield takeEvery(
    actions.UPDATE_STATIC_PAGE,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(staticPageService.updateStaticPage, model);
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}

export function* updateLandingPage() {
  yield takeEvery(
    actions.UPDATE_LANDING_PAGE,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(staticPageService.updateLandingPage, model);
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export default function* rootSaga() {
  yield all([
    fork(getStaticPage),
    fork(getStaticPageDetail),
    fork(updateStaticPage),
    fork(getLandingPage),
    fork(updateLandingPage),
    fork(getImgLandingPage),
  ]);
}
