import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as packageServices from '@iso/services/package';

export function* getPackage() {
    yield takeEvery(actions.GET_PACKAGE, function* ({ payload }) {
        const { model } = payload;
        try {
            var response = yield call(packageServices.getPackage, model);
            if (response.data.success === true) {
                var data = response.data.data.data;
                var totalItems = response.data.data.total;
                yield put({
                    type: actions.GET_PACKAGE_SUCCESS,
                    payload: { data, totalItems }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_PACKAGE_ERROR,
            })
        }
    })
}
export function* createPackage() {
    yield takeEvery(actions.CREATE_PAKAGE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(packageServices.createPackage, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* deletePackage() {
    yield takeEvery(actions.DELETE_PACKAGE, function* ({ payload, cbSuccess, cbError }) {
        const { id } = payload;
        try {
            var response = yield call(packageServices.deletePackage, id);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export function* getPackageDetail() {
    yield takeEvery(actions.GET_PACKAGE_DETAIL, function* ({ payload }) {
        const { id } = payload;
        try {
            var response = yield call(packageServices.getPackageDetail, id);
            if (response.data.success === true) {
                var data = response.data.data;
                yield put({
                    type: actions.GET_PACKAGE_DETAIL_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({
                type: actions.GET_PACKAGE_DETAIL_ERROR,
            })
        }
    })
}
export function* updatePackage() {
    yield takeEvery(actions.UPDATE_PACKAGE, function* ({ payload, cbSuccess, cbError }) {
        const { model } = payload;
        try {
            var response = yield call(packageServices.updatePackage, model);
            if (response.data.success === true) {
                yield cbSuccess();
            }
        } catch (e) {
            if (e.response && e.response.data)
                yield cbError(e.response.data.ErrorMessage);
            else
                yield cbError();
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getPackage),
        fork(createPackage),
        fork(deletePackage),
        fork(getPackageDetail),
        fork(updatePackage),
    ])
}
