const actions = {

    GET_TEACHING_SCHEDULES: 'GET_TEACHING_SCHEDULES',
    GET_TEACHING_SCHEDULES_SUCCESS: 'GET_TEACHING_SCHEDULES_SUCCESS',
    GET_TEACHING_SCHEDULES_ERROR: 'GET_TEACHING_SCHEDULES_ERROR',

    GET_TEACHING_SCHEDULE_DETAIL: 'GET_TEACHING_SCHEDULE_DETAIL',
    GET_TEACHING_SCHEDULE_DETAIL_SUCCESS: 'GET_TEACHING_SCHEDULE_DETAIL_SUCCESS',
    GET_TEACHING_SCHEDULE_DETAIL_ERROR: 'GET_TEACHING_SCHEDULE_DETAIL_ERROR',

    CREATE_TEACHING_SCHEDULE: 'CREATE_TEACHING_SCHEDULE',
    CREATE_TEACHING_SCHEDULE_FILES: 'CREATE_TEACHING_SCHEDULE_FILES',

    UPDATE_TEACHING_SCHEDULE: 'UPDATE_TEACHING_SCHEDULE',

    DELETE_TEACHING_SCHEDULE: 'DELETE_TEACHING_SCHEDULE',
    DELETE_TEACHING_SCHEDULE_FILE: 'DELETE_TEACHING_SCHEDULE_FILE',

    GET_MEMBERS_REGISTERED: "GET_MEMBERS_REGISTERED",
    GET_MEMBERS_REGISTERED_SUCCESS: "GET_MEMBERS_REGISTERED_SUCCESS",
    GET_MEMBERS_REGISTERED_ERROR: "GET_MEMBERS_REGISTERED_ERROR",

    GET_SELECT_LIST: "GET_SELECT_LIST",
    GET_SELECT_LIST_SUCCESS: "GET_SELECT_LIST_SUCCESS",
    GET_SELECT_LIST_ERROR: "GET_SELECT_LIST_ERROR",


    //#region CRUD
    getTeachingSchedules: model => ({
        type: actions.GET_TEACHING_SCHEDULES,
        payload: { model }
    }),
    deleteTeachingSchedule: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_TEACHING_SCHEDULE,
        payload: { id },
        cbSuccess, cbError
    }),
    deleteTeachingScheduleFile: (model, _cbSuccess, _cbError) => ({
        type: actions.DELETE_TEACHING_SCHEDULE_FILE,
        payload: { model },
        _cbSuccess, _cbError
    }),
    getTeachingScheduleDetail: (id, cbSuccess, cbError) => ({
        type: actions.GET_TEACHING_SCHEDULE_DETAIL,
        payload: { id },
        cbSuccess, cbError
    }),
    createTeachingSchedule: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_TEACHING_SCHEDULE,
        payload: { model },
        cbSuccess, cbError
    }),
    createTeachingScheduleFiles: (model, _cbSuccess, _cbError, _onUploadProgress) => ({
        type: actions.CREATE_TEACHING_SCHEDULE_FILES,
        payload: { model },
        _cbSuccess, _cbError, _onUploadProgress
    }),
    updateTeachingSchedule: (id, model, cbSuccess, cbError) => ({
        type: actions.UPDATE_TEACHING_SCHEDULE,
        payload: { id, model },
        cbSuccess, cbError
    }),
    getMembersRegistered: (model) => ({
        type: actions.GET_MEMBERS_REGISTERED,
        payload: { model },
    }),
    getSelectList: (model) => ({
        type: actions.GET_SELECT_LIST,
        payload: { model },
    })
    //#endregion
}

export default actions;