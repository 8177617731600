import { ApiRouters } from '@iso/utils/apiRouters';
import { api, axiosDownload } from '@iso/utils/axios.configs';
import axios from "axios";

export const getLecturerListService = (model) => {
  var url = ApiRouters.LECTURERS;
  return api.get(url, { params: model });
}

export const getLecturerDetailService = (id) => {
  var url = `${ApiRouters.LECTURERS}/${id}`;
  return api.get(url);
}

export const createLecturerService = (body) => {
  var url = ApiRouters.LECTURERS;
  return api.post(url, body);
}

export const createLecturerAvatarService = (model) => {
  var url = `${ApiRouters.LECTURERS}/${model.id}/Avatar`;
  return api.post(url, model.body);
}

export const deleteLecturerService = (id) => {
  var url = `${ApiRouters.LECTURERS}/${id}`;
  return api.delete(url);
}

export const updateLecturerService = (model) => {
  var url = `${ApiRouters.LECTURERS}/${model.id}`;
  return api.put(url, model);
}

export const getLecturerSelectboxService = (model) => {
  var url = `${ApiRouters.LECTURERS}/SelectList`;
  return api.get(url, { params: model });
}

export const getLecturerRevenueService = (model) => {
  var url = `${ApiRouters.LECTURERS}/${model.id}/Revenues`;
  return api.get(url, { params: model });
  // return api.get(url, model);
}

export const getLecturerPayment = (model) => {

  var url = `${ApiRouters.LECTURERS}/${model.id}/RevenuePayments`;
  return api.get(url, { params: model });
  // return api.get(url, model);

}
export const createLecturerPayment = (id, model) => {
  var url = `${ApiRouters.LECTURERS}/${id}/RevenuePayments`;
  return api.post(url, model);
}

// export const getLecturerExportPayment = (id) => {
//   var url = `${ApiRouters.LECTURERS}/${id}/RevenuePaymentsExport`;
//   return api.get(url);
// }
export const exportLecturerPayment = (model) => {
  let baseURL = process.env.REACT_APP_API_KEY;
  var url = baseURL + `${ApiRouters.LECTURERS}/${model.id}/RevenuePaymentsExport`;
  return axios(axiosDownload(url, "get", model, null));
}
export const exportLecturerRevenue = (model) => {
  let baseURL = process.env.REACT_APP_API_KEY;
  var url = baseURL + `${ApiRouters.LECTURERS}/${model.id}/RevenuesExport`;
  return axios(axiosDownload(url, "get", model, null));
}
