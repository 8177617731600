import * as teachingScheduleService from '@iso/services/teachingSchedule';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region GET
export function* getTeachingSchedules() {
  yield takeEvery(actions.GET_TEACHING_SCHEDULES, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(teachingScheduleService.getTeachingSchedules, model);
      if (response.data.success === true) {
        var data = response.data.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_TEACHING_SCHEDULES_SUCCESS,
          payload: { data, totalItems }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TEACHING_SCHEDULES_ERROR,
      })
    }
  })
}

export function* getTeachingScheduleDetail() {
  yield takeEvery(actions.GET_TEACHING_SCHEDULE_DETAIL, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(teachingScheduleService.getTeachingScheduleDetail, id);
      if (response.data.success === true) {
        var data = response.data.data;
        if (cbSuccess)
          yield cbSuccess(data);
        yield put({
          type: actions.GET_TEACHING_SCHEDULE_DETAIL_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_TEACHING_SCHEDULE_DETAIL_ERROR,
      })
    }
  })
}

export function* getMembersRegistered() {
  yield takeEvery(actions.GET_MEMBERS_REGISTERED, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(teachingScheduleService.getMemberRegistered, model);
      if (response.data.success === true) {
        const { data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_MEMBERS_REGISTERED_SUCCESS,
          payload: { data, totalItems }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_MEMBERS_REGISTERED_ERROR,
      });
      // if (e.response && e.response.data)
      //   yield _cbError(e.response.data.ErrorMessage);
      // else
      //   yield _cbError();
    }
  })
}

//#endregion

//#region CURD
export function* deleteTeachingSchedule() {
  yield takeEvery(actions.DELETE_TEACHING_SCHEDULE, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(teachingScheduleService.deleteTeachingSchedule, id);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
export function* deleteTeachingScheduleFile() {
  yield takeEvery(actions.DELETE_TEACHING_SCHEDULE_FILE, function* ({ payload, _cbSuccess, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(teachingScheduleService.deleteTeachingScheduleFile, model);
      if (response.data.success === true) {
        yield _cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}

export function* createTeachingSchedule() {
  yield takeEvery(actions.CREATE_TEACHING_SCHEDULE, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(teachingScheduleService.createTeachingSchedule, model);
      if (response.data.success === true) {
        const { id } = response.data.data;
        yield cbSuccess(id);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* createTeachingScheduleFiles() {
  yield takeEvery(actions.CREATE_TEACHING_SCHEDULE_FILES, function* ({ payload, _cbSuccess, _cbError, _onUploadProgress }) {
    const { model } = payload;
    try {
      var response = yield call(teachingScheduleService.createTeachingScheduleFiles, model, _onUploadProgress);
      if (response.data.success === true) {
        yield _cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}

export function* updateTeachingSchedule() {
  yield takeEvery(actions.UPDATE_TEACHING_SCHEDULE, function* ({ payload, cbSuccess, cbError }) {
    const { id, model } = payload;
    try {
      var response = yield call(teachingScheduleService.updateTeachingSchedule, id, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* getSelectList() {
  yield takeEvery(actions.GET_SELECT_LIST, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(teachingScheduleService.getSelectList, model);
      if (response.data.success === true) {
        var { data } = response.data;
        yield put({
          type: actions.GET_SELECT_LIST_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({ type: actions.GET_SELECT_LIST_ERROR })
    }
  })
}
//#endregion


export default function* rootSaga() {
  yield all([
    fork(getTeachingSchedules),
    fork(deleteTeachingSchedule),
    fork(deleteTeachingScheduleFile),
    fork(getTeachingScheduleDetail),
    fork(createTeachingSchedule),
    fork(createTeachingScheduleFiles),
    fork(updateTeachingSchedule),
    fork(getMembersRegistered),
    fork(getSelectList)
  ])
}