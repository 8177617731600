import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getBanner = () => {
    var url = ApiRouters.BANNER;
    return api.get(url);
}
export const createBanner = body => {
    var url = ApiRouters.BANNER;
    return api.post(url, body);
};
export const deleteBanner = id => {
    var url = `${ApiRouters.BANNER}/${id}`;
    return api.delete(url);
};