import { RootPaths } from "@iso/constants/Models";
import { ApiRouters } from '@iso/utils/apiRouters';
import { api, apiUpload, axiosDownload } from '@iso/utils/axios.configs';
import axios from "axios";

export const getDocuments = body => {
  var url = ApiRouters.DOCUMENT;
  return api.get(url, { params: body });
};
export const deleteDocument = id => {
  var url = `${ApiRouters.DOCUMENT}/${id}`;
  return api.delete(url);
};
export const deleteDocumentFile = model => {
  var url = `${ApiRouters.DOCUMENT}/${model.id}/Files`;
  return api.delete(url, { data: model.body });
};
export const getDocumentDetail = id => {
  var url = `${ApiRouters.DOCUMENT}/${id}`;
  return api.get(url);
};
export const createDocument = body => {
  var url = ApiRouters.DOCUMENT;
  return api.post(url, body);
};
export const createDocumentFiles = (model, onUploadProgress) => {
  var url = `${ApiRouters.DOCUMENT}/${model.id}/Files`;
  return axios(apiUpload(url, 'post', model.body, onUploadProgress)); 
};
export const updateDocument = (id, model) => {
  var url = `${ApiRouters.DOCUMENT}/${id}`;
  return api.put(url, model);
};
export const downloadDocumentFileService = (model) => {
  var url = getPathNameService(model);
  return axios(axiosDownload(url, "get", model, null));
}
export const getPathNameService = (model) => {
  let url = process.env.REACT_APP_API_KEY;
  switch (model.PathName) {
    case RootPaths.TeachingSchedules:
      url = url + `${ApiRouters.TEACHINGSCHEDULE}/${model.id}/Files/${model.fileId}`;
      break;
    case RootPaths.Documents:
      url = url + `${ApiRouters.DOCUMENT}/${model.id}/Files/${model.fileId}`;
      break;
    default: break;
  }
  return url;
}