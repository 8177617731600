import actions from './actions';

const initState = {
  livestreams: [],
  livestreamDetail: {},
  loading: false,
  totalItems: 0,
};

export default function livestreamReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_LIVESTREAMS:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_LIVESTREAMS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, livestreams: res, totalItems: totalItems, loading: false };
    }
    case actions.GET_LIVESTREAMS_ERROR:
      return { ...state, loading: false };

    case actions.GET_LIVESTREAM_DETAIL:
      return { ...state, loading: true };
    case actions.GET_LIVESTREAM_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, livestreamDetail: res, loading: false };
    }
    case actions.GET_LIVESTREAM_DETAIL_ERROR:
      return { ...state, loading: false };
    //#endregion

    default:
      return state;

  }
}
