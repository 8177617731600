import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getPackage = body => {
    var url = ApiRouters.PACKAGE;
    return api.get(url, { params: body });
};
export const createPackage = body => {
    var url = ApiRouters.PACKAGE;
    return api.post(url, body);
};
export const deletePackage = id => {
    var url = `${ApiRouters.PACKAGE}/${id}`;
    return api.delete(url);
};
export const getPackageDetail = id => {
    var url = `${ApiRouters.PACKAGE}/${id}`;
    return api.get(url);
};
export const updatePackage = body => {
    var url = `${ApiRouters.PACKAGE}/${body.id}`;
    return api.put(url, body);
};
