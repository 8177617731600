const actions = {
  //#region Select box
  RESET_CATEGORY_SELECTBOX: "RESET_CATEGORY_SELECTBOX", 

  resetCategorySelectbox: () => ({
    type: actions.RESET_CATEGORY_SELECTBOX,    
  }),
  //#endregion

  //#region Select box
  GET_CATEGORY_SELECTBOX: "GET_CATEGORY_SELECTBOX",
  GET_CATEGORY_SELECTBOX_SUCCESS: "GET_CATEGORY_SELECTBOX_SUCCESS",
  GET_CATEGORY_SELECTBOX_ERROR: "GET_CATEGORY_SELECTBOX_ERROR",

  getCategorySelectbox: (model, _cbError) => ({
    type: actions.GET_CATEGORY_SELECTBOX,
    payload: { model },
    _cbError,
  }),
  //#endregion
  GET_CATEGORY: "GET_CATEGORY",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_ERROR: "GET_CATEGORY_ERROR",

  getCategory: model => ({
    type: actions.GET_CATEGORY,
    payload: { model }
  }),

  CREATE_CATEGORY: "CREATE_CATEGORY",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_ERROR: "CREATE_CATEGORY_ERROR",

  createCategory: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_CATEGORY,
    payload: { model },
    cbSuccess,
    cbError
  }),

  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR",

  deleteCategory: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_CATEGORY,
    payload: { id },
    cbSuccess,
    cbError,
  }),

  GET_CATEGORY_DETAIL: "GET_CATEGORY_DETAIL",
  GET_CATEGORY_DETAIL_SUCCESS: "GET_CATEGORY_DETAIL_SUCCESS",
  GET_CATEGORY_DETAIL_ERROR: "GET_CATEGORY_DETAIL_ERROR",

  getCategoryDetail: id => ({
    type: actions.GET_CATEGORY_DETAIL,
    payload: { id }
  }),
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_ERROR: "UPDATE_CATEGORY_ERROR",

  updateCategory: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_CATEGORY,
    payload: { model },
    cbSuccess, cbError
  }),
}

export default actions;