import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import * as basicDocumentService from '@iso/services/basicDocument';

export function* getBasicDocument() {
    yield takeEvery(actions.GET_BASIC_DOCUMENT, function* ({ payload }) {
        try {
            var response = yield call(basicDocumentService.getBasicDocument);
            if (response.status === 200) {
                var { data } = response.data;
                yield put({
                    type: actions.GET_BASIC_DOCUMENT_SUCCESS,
                    payload: { data }
                })
            }
        } catch (e) {
            yield put({ type: actions.GET_BASIC_DOCUMENT_ERROR, })
        }
    })
}
export function* updateBasicDocument() {
    yield takeEvery(actions.UPDATE_BASIC_DOCUMENT, function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(basicDocumentService.updateBasicDocument, model);
        if (response.status === 200) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else
          yield cbError();
      }
    })
  }
export default function* rootSaga() {
    yield all([
        fork(getBasicDocument),
        fork(updateBasicDocument),
    ])
}