import { ApiRouters } from '@iso/utils/apiRouters';
import { api, axiosDownload } from '@iso/utils/axios.configs';
import axios from "axios";

export const getDocumentsOrderedService = (model) => {
  var url = ApiRouters.PAYMENT_DOCUMENTS;
  return api.get(url, { params: model });
};
export const getDocumentOrderedDetailService = (id) => {
  var url = `${ApiRouters.PAYMENT_DOCUMENTS}/${id}`;
  return api.get(url);
}
export const getAccountsActivatedService = (model) => {
  var url = ApiRouters.PAYMENT_ACCOUNTS;
  return api.get(url, { params: model });
};
export const getAccountActivatedDetailService = (id) => {
  var url = `${ApiRouters.PAYMENT_ACCOUNTS}/${id}`;
  return api.get(url);
}
export const postAccountActivatedDetailService = (id) => {
  var url = `${ApiRouters.PAYMENT_ACCOUNTS}/${id}/confirm`;
  return api.post(url);
}
export const postAccountActivatedDetailRejectService = (id, body) => {
  var url = `${ApiRouters.PAYMENT_ACCOUNTS}/${id}/reject`;
  return api.post(url, body);
}
export const postDocumentOrderService = (id) => {
  var url = `${ApiRouters.PAYMENT_DOCUMENTS}/${id}/confirm`;
  return api.post(url);
}
export const postDocumentOrderRejectService = (id, body) => {
  var url = `${ApiRouters.PAYMENT_DOCUMENTS}/${id}/reject`;
  return api.post(url, body);
}
export const exportDocumentPayments = (model) => {
  let baseURL = process.env.REACT_APP_API_KEY;
  var url = baseURL + `${ApiRouters.PAYMENT_DOCUMENTS}/Export`;
  return axios(axiosDownload(url, "get", model, null));
}
export const exportMembershipActivationPayment = (model) => {
  let baseURL = process.env.REACT_APP_API_KEY;
  var url = baseURL + `${ApiRouters.PAYMENT_ACCOUNTS}/Export`;
  return axios(axiosDownload(url, "get", model, null));
}

