import * as livestreamService from '@iso/services/livestream';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';

//#region CRUD
export function* getLivestreams() {
  yield takeEvery(actions.GET_LIVESTREAMS, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(livestreamService.getLivestreams, model);
      if (response.data.success === true) {
        var data = response.data.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_LIVESTREAMS_SUCCESS,
          payload: { data, totalItems }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_LIVESTREAMS_ERROR,
      })
    }
  })
}

export function* deleteLivestream() {
  yield takeEvery(actions.DELETE_LIVESTREAM, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(livestreamService.deleteLivestream, id);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* deleteLivestreamBanner() {
  yield takeEvery(actions.DELETE_LIVESTREAM_BANNER, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(livestreamService.deleteLivestreamBanner, model);
    } catch (e) {
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}

export function* getLivestreamDetail() {
  yield takeEvery(actions.GET_LIVESTREAM_DETAIL, function* ({ payload }) {
    const { id } = payload;
    try {
      var response = yield call(livestreamService.getLivestreamDetail, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_LIVESTREAM_DETAIL_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_LIVESTREAM_DETAIL_ERROR,
      })
    }
  })
}

export function* createLivestream() {
  yield takeEvery(actions.CREATE_LIVESTREAM, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(livestreamService.createLivestream, model);
      if (response.data.success === true) {
        const { id } = response.data.data;
        yield cbSuccess(id);
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* createLivestreamBanner() {
  yield takeEvery(actions.CREATE_LIVESTREAM_BANNER, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(livestreamService.createLivestreamBanner, model);
    } catch (e) {
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}

export function* updateLivestream() {
  yield takeEvery(actions.UPDATE_LIVESTREAM, function* ({ payload, cbSuccess, cbError }) {
    const { id, model } = payload;
    try {
      var response = yield call(livestreamService.updateLivestream, id, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getLivestreams),
    fork(deleteLivestream),
    fork(deleteLivestreamBanner),
    fork(getLivestreamDetail),
    fork(createLivestream),
    fork(createLivestreamBanner),
    fork(updateLivestream),
  ])
}