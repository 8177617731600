import { RootPaths } from "@iso/constants/Models";
import { ApiRouters } from '@iso/utils/apiRouters';
import { api, apiUpload } from '@iso/utils/axios.configs';
import qs from 'qs';
import axios from "axios";

//#region GET
export const getTeachingSchedules = body => {
  var url = ApiRouters.TEACHINGSCHEDULE;
  return api.get(url, { params: body });
};
export const getTeachingScheduleDetail = id => {
  var url = `${ApiRouters.TEACHINGSCHEDULE}/${id}`;
  return api.get(url);
};
export const getMemberRegistered = model => {
  var url = getPathNameService(model);
  return api.get(url, { params: model });
};
export const getSelectList = model => {
  var url = `${ApiRouters.LECTURERS}/SelectList`;
  return api.get(url, {
    params: model,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  });
};

export const getPathNameService = (model) => {
  let url = '';
  switch (model.PathName) {
    case RootPaths.TeachingSchedules:
      url = url + `${ApiRouters.TEACHINGSCHEDULE}/${model.id}/RegisteredMemberships`;
      break;
    case RootPaths.Livestreams:
      url = url + `${ApiRouters.LIVESTREAM}/${model.id}/RegisteredMemberships`;
      break;
    case RootPaths.Events:
      url = url + `${ApiRouters.EVENT}/${model.id}/RegisteredMemberships`;
      break;

    default: break;
  }
  return url;
}
//#endregion

//#region CURD
export const deleteTeachingSchedule = id => {
  var url = `${ApiRouters.TEACHINGSCHEDULE}/${id}`;
  return api.delete(url);
};
export const deleteTeachingScheduleFile = model => {
  var url = `${ApiRouters.TEACHINGSCHEDULE}/${model.id}/Files`;
  return api.delete(url, { data: model.body });
};
export const createTeachingSchedule = body => {
  var url = ApiRouters.TEACHINGSCHEDULE;
  return api.post(url, body);
};
export const createTeachingScheduleFiles = (model, onUploadProgress) => {
  var url = `${ApiRouters.TEACHINGSCHEDULE}/${model.id}/Files`;
  return axios(apiUpload(url, 'post', model.body, onUploadProgress));  
};
export const updateTeachingSchedule = (id, body) => {
  var url = `${ApiRouters.TEACHINGSCHEDULE}/${id}`;
  return api.put(url, body);
};
//#endregion