import { api } from '@iso/utils/axios.configs';
import { ApiRouters } from '@iso/utils/apiRouters';

export const getBasicDocument = () => {
    var url = ApiRouters.BASICDOCUMENT;
    return api.get(url);
};
export const updateBasicDocument = body => {
    var url = ApiRouters.BASICDOCUMENT;
    return api.put(url, body);
};