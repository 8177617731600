const actions = {
  GET_STATIC_PAGE: "GET_STATIC_PAGE",
  GET_STATIC_PAGE_SUCCESS: "GET_STATIC_PAGE_SUCCESS",
  GET_STATIC_PAGE_ERROR: "GET_STATIC_PAGE_ERROR",

  GET_STATIC_PAGE_DETAIL: "GET_STATIC_PAGE_DETAIL",
  GET_STATIC_PAGE_DETAIL_SUCCESS: "GET_STATIC_PAGE_DETAIL_SUCCESS",
  GET_STATIC_PAGE_DETAIL_ERROR: "GET_STATIC_PAGE_DETAIL_ERROR",

  UPDATE_STATIC_PAGE: "UPDATE_STATIC_PAGE",
  UPDATE_STATIC_PAGE_SUCCESS: "UPDATE_STATIC_PAGE_SUCCESS",
  UPDATE_STATIC_PAGE_ERROR: "UPDATE_STATIC_PAGE_ERROR",

  UPDATE_LANDING_PAGE: "UPDATE_LANDING_PAGE",
  UPDATE_LANDING_PAGE_SUCCESS: "UPDATE_LANDING_PAGE_SUCCESS",
  UPDATE_LANDING_PAGE_ERROR: "UPDATE_LANDING_PAGE_ERROR",

  GET_LANDING_PAGE: "GET_LANDING_PAGE",
  GET_LANDING_PAGE_SUCCESS: "GET_LANDING_PAGE_SUCCESS",
  GET_LANDING_PAGE_ERROR: "GET_LANDING_PAGE_ERROR",

  GET_IMG_LANDING_PAGE: "GET_IMG_LANDING_PAGE",
  GET_IMG_LANDING_PAGE_SUCCESS: "GET_IMG_LANDING_PAGE_SUCCESS",
  GET_IMG_LANDING_PAGE_ERROR: "GET_IMG_LANDING_PAGE_ERROR",

  updateStaticPage: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_STATIC_PAGE,
    payload: { model },
    cbSuccess,
    cbError,
  }),

  updateLandingPage: (model, cbSuccess, cbError) => ({
    type: actions.UPDATE_LANDING_PAGE,
    payload: { model },
    cbSuccess,
    cbError,
  }),

  getStaticPageDetail: (id) => ({
    type: actions.GET_STATIC_PAGE_DETAIL,
    payload: { id },
  }),

  getStaticPage: (model) => ({
    type: actions.GET_STATIC_PAGE,
    payload: { model },
  }),

  getLandingPage: (model) => ({
    type: actions.GET_LANDING_PAGE,
    payload: { model },
  }),

  getImgLandingPage: (model) => ({
    type: actions.GET_IMG_LANDING_PAGE,
    payload: { model },
  }),
};
export default actions;
