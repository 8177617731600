import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import * as bannerService from "@iso/services/banner";

export function* getBanner() {
  yield takeEvery(actions.GET_BANNER, function* ({ payload }) {
    try {
      var response = yield call(bannerService.getBanner);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_BANNER_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_BANNER_ERROR });
    }
  });
}
export function* createBanner() {
  yield takeEvery(
    actions.CREATE_BANNER,
    function* ({ payload, cbSuccess, cbError }) {
      const { body } = payload;
      try {
        var response = yield call(bannerService.createBanner, body);
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export function* deleteBanner() {
  yield takeEvery(
    actions.DELETE_BANNER,
    function* ({ payload, cbSuccess, cbError }) {
      const { id } = payload;
      try {
        var response = yield call(bannerService.deleteBanner, id);
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export default function* rootSaga() {
  yield all([fork(getBanner), fork(createBanner), fork(deleteBanner)]);
}
