const actions = {

  GET_LIVESTREAMS: 'GET_LIVESTREAMS',
  GET_LIVESTREAMS_SUCCESS: 'GET_LIVESTREAMS_SUCCESS',
  GET_LIVESTREAMS_ERROR: 'GET_LIVESTREAMS_ERROR',

  GET_LIVESTREAM_DETAIL: 'GET_LIVESTREAM_DETAIL',
  GET_LIVESTREAM_DETAIL_SUCCESS: 'GET_LIVESTREAM_DETAIL_SUCCESS',
  GET_LIVESTREAM_DETAIL_ERROR: 'GET_LIVESTREAM_DETAIL_ERROR',

  CREATE_LIVESTREAM: 'CREATE_LIVESTREAM',
  CREATE_LIVESTREAM_BANNER: 'CREATE_LIVESTREAM_BANNER',

  UPDATE_LIVESTREAM: 'UPDATE_LIVESTREAM',

  DELETE_LIVESTREAM: 'DELETE_LIVESTREAM',
  DELETE_LIVESTREAM_BANNER: 'DELETE_LIVESTREAM_BANNER',

  //#region CRUD
  getLivestreams: model => ({
    type: actions.GET_LIVESTREAMS,
    payload: { model }
  }),
  deleteLivestream: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_LIVESTREAM,
    payload: { id },
    cbSuccess, cbError
  }),
  deleteLivestreamBanner: (model, cbError) => ({
    type: actions.DELETE_LIVESTREAM_BANNER,
    payload: { model },
    cbError
  }),
  getLivestreamDetail: id => ({
    type: actions.GET_LIVESTREAM_DETAIL,
    payload: { id }
  }),
  createLivestream: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_LIVESTREAM,
    payload: { model },
    cbSuccess, cbError
  }),
  createLivestreamBanner: (model, _cbError) => ({
    type: actions.CREATE_LIVESTREAM_BANNER,
    payload: { model },
    _cbError
  }),
  updateLivestream: (id, model, cbSuccess, cbError) => ({
    type: actions.UPDATE_LIVESTREAM,
    payload: { id, model },
    cbSuccess, cbError
  }),
  //#endregion
}

export default actions;