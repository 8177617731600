import actions from "./actions";
const initState = {
    listBanner: [],
    loading: false,
}
export default function bannerReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_BANNER:
            return { ...state, loading: true };
        case actions.GET_BANNER_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : [];
            return { ...state, listBanner: res, loading: false };
        }
        case actions.GET_BANNER_ERROR:
            return { ...state, loading: false };
        
        default:
            return state;
    }
}