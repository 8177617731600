const actions = {

  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_ERROR: 'GET_DOCUMENTS_ERROR',

  GET_DOCUMENT_DETAIL: 'GET_DOCUMENT_DETAIL',
  GET_DOCUMENT_DETAIL_SUCCESS: 'GET_DOCUMENT_DETAIL_SUCCESS',
  GET_DOCUMENT_DETAIL_ERROR: 'GET_DOCUMENT_DETAIL_ERROR',

  CREATE_DOCUMENT: 'CREATE_DOCUMENT',
  CREATE_DOCUMENT_FILES: 'CREATE_DOCUMENT_FILES',

  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',

  DELETE_DOCUMENT: 'DELETE_DOCUMENT',

  DELETE_DOCUMENT_FILE: 'DELETE_DOCUMENT_FILE',

  DOWNLOAD_DOCUMENT_FILE: "DOWNLOAD_DOCUMENT_FILE",
  //#region CRUD
  getDocuments: model => ({
    type: actions.GET_DOCUMENTS,
    payload: { model }
  }),
  deleteDocument: (id, cbSuccess, cbError) => ({
    type: actions.DELETE_DOCUMENT,
    payload: { id },
    cbSuccess, cbError
  }),
  deleteDocumentFile: (model, cbSuccess, cbError) => ({
    type: actions.DELETE_DOCUMENT_FILE,
    payload: { model },
    cbSuccess, cbError
  }),
  getDocumentDetail: (id, _cbError) => ({
    type: actions.GET_DOCUMENT_DETAIL,
    payload: { id },
    _cbError,
  }),
  createDocument: (model, cbSuccess, cbError) => ({
    type: actions.CREATE_DOCUMENT,
    payload: { model },
    cbSuccess, cbError
  }),
  createDocumentFiles: (model, _cbSuccess, _cbError, _onUploadProgress) => ({
    type: actions.CREATE_DOCUMENT_FILES,
    payload: { model },
    _cbSuccess,
    _cbError,
    _onUploadProgress,
  }),
  updateDocument: (id, model, cbSuccess, cbError) => ({
    type: actions.UPDATE_DOCUMENT,
    payload: { id, model },
    cbSuccess, cbError
  }),
  downloadDocumentFile: (model, _cbSuccess, _cbError) => ({
    type: actions.DOWNLOAD_DOCUMENT_FILE,
    payload: { model },
    _cbSuccess, _cbError,
  }),
  //#endregion
}

export default actions;