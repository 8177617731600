import actions from './actions';

const initState = {
  teachingSchedules: [],
  teachingScheduleDetail: {},
  loading: false,
  totalItems: 0,
  members: [],
  selectList: [],
};

export default function teachingScheduleReducer(state = initState, action) {
  switch (action.type) {

    //#region GET
    case actions.GET_TEACHING_SCHEDULES:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_TEACHING_SCHEDULES_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, teachingSchedules: res, totalItems: totalItems, loading: false };
    }
    case actions.GET_TEACHING_SCHEDULES_ERROR:
      return { ...state, loading: false };

    case actions.GET_TEACHING_SCHEDULE_DETAIL:
      return { ...state, loading: true };
    case actions.GET_TEACHING_SCHEDULE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, teachingScheduleDetail: res, loading: false };
    }
    case actions.GET_TEACHING_SCHEDULE_DETAIL_ERROR:
      return { ...state, loading: false };

    case actions.GET_MEMBERS_REGISTERED:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_MEMBERS_REGISTERED_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, members: res, totalItems: totalItems, loading: false };
    }

    case actions.GET_MEMBERS_REGISTERED_ERROR:
      return { ...state, loading: false };

    case actions.GET_SELECT_LIST_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : [];
      return { ...state, selectList: res };
    }
    //#endregion

    default:
      return state;

  }
}
