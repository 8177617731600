const actions = {
  GET_DOCUMENTS_ORDERED: "GET_DOCUMENTS_ORDERED",
  GET_DOCUMENTS_ORDERED_SUCCESS: "GET_DOCUMENTS_ORDERED_SUCCESS",
  GET_DOCUMENTS_ORDERED_ERROR: "GET_DOCUMENTS_ORDERED_ERROR",

  getDocumentsOrdered: (model, _cbError) => ({
    type: actions.GET_DOCUMENTS_ORDERED,
    payload: { model },
    _cbError,
  }),

  GET_DOCUMENT_ORDERED_DETAIL: "GET_DOCUMENT_ORDERED_DETAIL",
  GET_DOCUMENT_ORDERED_DETAIL_SUCCESS: "GET_DOCUMENT_ORDERED_DETAIL_SUCCESS",
  GET_DOCUMENT_ORDERED_DETAIL_ERROR: "GET_DOCUMENT_ORDERED_DETAIL_ERROR",

  getDocumentOrderedDetail: (id, _cbError) => ({
    type: actions.GET_DOCUMENT_ORDERED_DETAIL,
    payload: { id },
    _cbError,
  }),

  GET_ACCOUNTS_ACTIVATED: "GET_ACCOUNTS_ACTIVATED",
  GET_ACCOUNTS_ACTIVATED_SUCCESS: "GET_ACCOUNTS_ACTIVATED_SUCCESS",
  GET_ACCOUNTS_ACTIVATED_ERROR: "GET_ACCOUNTS_ACTIVATED_ERROR",

  getAccountsActivated: (model, _cbError) => ({
    type: actions.GET_ACCOUNTS_ACTIVATED,
    payload: { model },
    _cbError,
  }),

  GET_ACCOUNT_ACTIVATED_DETAIL: "GET_ACCOUNT_ACTIVATED_DETAIL",
  GET_ACCOUNT_ACTIVATED_DETAIL_SUCCESS: "GET_ACCOUNT_ACTIVATED_DETAIL_SUCCESS",
  GET_ACCOUNT_ACTIVATED_DETAIL_ERROR: "GET_ACCOUNT_ACTIVATED_DETAIL_ERROR",

  getAccountActivatedDetail: (id, _cbError) => ({
    type: actions.GET_ACCOUNT_ACTIVATED_DETAIL,
    payload: { id },
    _cbError,
  }),

  EXPORT_DOCUMENT_PAYMENT: "EXPORT_DOCUMENT_PAYMENT",
  EXPORT_ERROR: "EXPORT_ERROR",

  exportDocumentPayment: (model) => ({
    type: actions.EXPORT_DOCUMENT_PAYMENT,
    payload: { model },
  }),
  EXPORT_MEMBERSHIPACTIVATION_PAYMENT: "EXPORT_MEMBERSHIPACTIVATION_PAYMENT",
  exportMembershipactivationPayment: (model) => ({
    type: actions.EXPORT_MEMBERSHIPACTIVATION_PAYMENT,
    payload: { model }
  }),

  POST_MEMBERSHIPACTIVATION_PAYMENT: "POST_MEMBERSHIPACTIVATION_PAYMENT",

  postMembershipactivationPayment: (id, _cbSuccess, _cbError) => ({
    type: actions.POST_MEMBERSHIPACTIVATION_PAYMENT,
    payload: { id },
    _cbSuccess,
    _cbError
  }),

  POST_MEMBERSHIPACTIVATION_PAYMENT_REJECT: "POST_MEMBERSHIPACTIVATION_PAYMENT_REJECT",

  postMembershipactivationPaymentReject: (id, model, _cbSuccess, _cbError) => ({
    type: actions.POST_MEMBERSHIPACTIVATION_PAYMENT_REJECT,
    payload: { id, model },
    _cbSuccess, _cbError
  }),

  POST_DOCUMENT_ORDER_PAYMENT: "POST_DOCUMENT_ORDER_PAYMENT",

  postDocumentOrderPayment: (id, _cbSuccess, _cbError) => ({
    type: actions.POST_DOCUMENT_ORDER_PAYMENT,
    payload: { id },
    _cbSuccess,
    _cbError
  }),

  POST_DOCUMENT_ORDER_PAYMENT_REJECT: "POST_DOCUMENT_ORDER_PAYMENT_REJECT",

  postDocumentOrderRejectPayment: (id, model, _cbSuccess, _cbError) => ({
    type: actions.POST_DOCUMENT_ORDER_PAYMENT_REJECT,
    payload: { id, model },
    _cbSuccess,
    _cbError
  })
};

export default actions;