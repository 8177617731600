const theme = {};

theme.palette = {
  primary: [
    "#4482FF", // 0: Default
    "#3A78F5", // 1: Darken 4%
    "#3775F2", // 2: Darken 5%
    "rgba(68, 130, 255, 0.2)", // 3: Fade 20%
    "#4C8AFF", // 4: Lighten 3%
    "rgba(68, 130, 255, 0.75)", // 5: Fade 75%
    "#6AA8FF", // 6: Lighten 15%
    "#63A1FF", // 7: Lighten 12%
    "#3F7DFA", // 8: Darken 2%
    "#3369e7", // 9: Algolia color
    "#5896FF", // 10: Lighten 8%
    "#2b69e6", // 11:
    "#236cfe", // 12: darken 10%
    "#4d88ff", // 13: Lighten 5%
  ],
  secondary: [
    "#2d3446", // 0: DarkBlue
    "#f1f3f6", // 1: LightBluish
    "#788195", // 2: LightBlue
    "#E4E6E9", // 3: LightBluish Darken 5%
    "#364d79", // 4:
    "#202739", // 5: DarkBlue Darken 5%
    "#f5f6f8", // 6: LighterBluish
    "#e9ebf1", // 7: DarkBluish
    "#F6F8FB", // 8: LighterBluish Lighten 2%
    "#E9EBEE", // 9: LighterBluish Darken 3%
    "#1a1a1a", // 10: Sidebar submenu select
  ],
  color: [
    "#FEAC01", // 0: Orange
    "#42299a", // 1: Purple
    "#F75D81", // 2: Pink
    "#7ED321", // 3: LimeGreen
    "#39435f", // 4: BlueShade
    "#FFCA28", // 5: Yellow
    "#F2BD1B", // 6: Yellow Darken 5%
    "#3b5998", // 7: Facebook
    "#344e86", // 8: Facebook Darken 5%
    "#dd4b39", // 9: Google Plus
    "#d73925", // 10: Google Plus Darken 5%
    "#e14615", // 11: Auth0
    "#ca3f13", // 12: Auth0
    "#e0364c", // 13: themeColor--AlizarinCrimson,
    "rgba(0, 0, 0, 0.85)", // 14: color default text.
    "#FFFFFF", // 15: basic white,
    "rgba(0, 0, 0, 0.2)", // 16: rgba lightest.
    "rgba(0, 0, 0, 0.4)", // 17: rgba light.
    "#C20044", // 18 Dark Red.
    "rgba(194, 0, 68, 0.06)", // 19 Light Red
    "#EB5757", // 20 Button Red
    "#4F4F4F", // 21 Button Gray
    "#212025", // 22 Main Black
  ],
  warning: [
    "#ffbf00", // 0: Warning
  ],
  success: [
    "#00b16a", // 0: Success - default
    "#0EB372", // 1: Semi- green
    "#00E166", // 2: Regular - green
    "#49E387", // 3: Light
    "#9FFAB2", // 4: Lighter
    "#CEFCD3", // 5: Lightest
    "#00a879", // 6: Button - Create new.
    "#4CAC26", // 7: Green status
  ],
  info: [
    "#0043EE", // 0: Semi
    "#0062FF", // 1: Regular
    "#219CFF", // 2: Light
    "#8CD9FF", // 3: Lighter
    "#C5EFFF", // 4: Lightest
    "#007399", // 5: Dark
  ],
  warning: [
    "#C19C00", // 0: Semi
    "#E0BB00", // 1: Regular
    "#ECD600", // 2: Light
    "#FCF767", // 3: Lighter
    "#FDFCB8", // 4: Lightest
  ],
  danger: [
    "#E70000", // 0: Semi
    "#FF1F00", // 1: Regular
    "#FF792F", // 2: Light
    "#FFC99D", // 3: Lighter
    "#FFE7CE", // 4: Lightest
  ],
  error: [
    "#f64744", // 0: Error
    "#EC3D3A", // 1: Darken 4%
    "#FF5B58", // 2: Lighten 8%
  ],
  grayscale: [
    "#bababa", // 0: GreyShade
    "#c1c1c1", // 1: GreyDark
    "#D8D8D8", // 2: Grey
    "#f1f1f1", // 3: GreyAlt
    "#F3F3F3", // 4: GreyLight
    "#fafafa", // 5: DarkWhite
    "#F9F9F9", // 6: DarkerWhite
    "#fcfcfc", // 7: #fff Darken 1%
    "#eeeeee", // 8:
    "#fbfbfb", // 9:
    "#f5f5f5", // 10:
    "#f7f8f9", // 11: today-highlight-bg
    "#d9d9d9", // 12: Gray Lighter,
    "rgba(0, 0, 0, 0.08)", // 13 Gray Status InActive.
  ],
  text: [
    "#323332", // 0: Heading
    "#595959", // 1: HeadingLight
    "#979797", // 2: Text
    "#797979", // 3: TextDark
    "#6a6c6a", // 4: Heading Lighten 22%
  ],
  border: [
    "#e9e9e9", // 0: Border
    "#d8d8d8", // 1: BorderDark
    "#ebebeb", // 2: BorderLight
    "#d3d3d3", // 3:
    "rgba(228, 228, 228, 0.65)", // 4:
  ],

  calendar: [
    "#905", // 0:
    "#690", // 1:
    "#a67f59", // 2:
    "#07a", // 3:
    "#dd4a68", // 4:
    "#e90", // 5:
  ],
  sidebar: [
    "#00824D", // 0 Green-Semi
    "#005845", // 1 Green-dark
    "#00984B", // 2 Green-medium
    "#59E081", // 3 Green-light,
    "#0EB372", // 4 Green
    "#00664d", // 5 Green sidebar
    "rgba(0, 152, 75, 0.06)", // 6 Green rgba,
    "#009B4E", // 7 Green color.
    "rgba(0, 152, 75, 0.1)", // 8 Green Status Active
    "#A97C50", // 9 Main Brown - 500,
    "#51220F", // 10 Brown - 900,
    "#623219", // 11 Brown - 800,
    "#91613A", // 12 Brown - 600,
    "#F6E4C2", // 13 Beige - 300,
    "#FAF2E0", // 14 Beige - 200,
  ],
};

theme.fonts = {
  primary: "Roboto, sans-serif",
  pre: "Consolas, Liberation Mono, Menlo, Courier, monospace",
};

export default theme;
