import * as memberService from "@iso/services/members";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD
export function* getMembersSaga() {
  yield takeEvery(actions.GET_MEMBERS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(memberService.getListMembersService, model);
      if (response.data.success === true) {
        const { data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_MEMBERS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_MEMBERS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* getMemberDetailSaga() {
  yield takeEvery(actions.GET_MEMBER_DETAIL, function* ({ payload, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(memberService.getMemberDetailService, id);
      if (response.data.success === true) {
        const { data } = response.data;
        yield put({
          type: actions.GET_MEMBER_DETAIL_SUCCESS,
          payload: { data },
        });
      } else {
        let { message } = response.data;
        _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_MEMBER_DETAIL_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  });
}

export function* createMemberSaga() {
  yield takeEvery(
    actions.CREATE_MEMBER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        var response = yield call(memberService.createMemberService, body);
        if (response.data.success === true) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* deleteMemberSaga() {
  yield takeEvery(
    actions.DELETE_MEMBER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { id } = payload;
      try {
        var response = yield call(memberService.deleteMemberService, id);
        if (response.data.success === true) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* updateMemberSaga() {
  yield takeEvery(
    actions.UPDATE_MEMBER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(memberService.updateMemberService, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* getMemberPurchasedDocument() {
  yield takeEvery(
    actions.GET_MEMBER_PURCHASED_DOCUMENT,
    function* ({ payload }) {
      const { model } = payload;
      try {
        var response = yield call(
          memberService.getMemberPurchasedDocuments,
          model
        );
        if (response.data.success === true) {
          var data = response.data.data;
          var totalItems = response.data.data.total;
          yield put({
            type: actions.GET_MEMBER_PURCHASED_DOCUMENT_SUCCESS,
            payload: { data, totalItems },
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_MEMBER_PURCHASED_DOCUMENT_ERROR,
        });
      }
    }
  );
}
export function* getMemberExtention() {
  yield takeEvery(actions.GET_MEMBER_EXTENSION, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(memberService.getMemberExtension, model);
      if (response.data.success === true) {
        var data = response.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_MEMBER_EXTENSION_SUCCESS,
          payload: { data, totalItems },
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_MEMBER_EXTENSION_ERROR,
      });
    }
  });
}
export function* postAvtivate() {
  yield takeEvery(
    actions.POST_ACTIVATE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(memberService.postActivate, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}

export function* getRegisteredActivities() {
  yield takeEvery(actions.GET_REGISTERED_ACTIVITIES, function* ({ payload }) {
    const { id, model } = payload;
    try {
      var response = yield call(
        memberService.getRegisteredActivities,
        id,
        model
      );
      if (response.data.success === true) {
        const { data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_REGISTERED_ACTIVITIES_SUCCESS,
          payload: { data, totalItems },
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_REGISTERED_ACTIVITIES_ERROR,
      });
      // if (e.response && e.response.data)
      //   yield _cbError(e.response.data.ErrorMessage);
      // else
      //   yield _cbError();
    }
  });
}
export function* resetPassword() {
  yield takeEvery(
    actions.MEMBER_RESET_PASSWORD,
    function* ({ payload, cbSuccess, cbError }) {
      const { model } = payload;
      try {
        var response = yield call(memberService.resetPasswordMember, model);
        if (response.data.success === true) {
          var res = response.data.data.password;
          yield cbSuccess(res);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export function* postMemberNotificationSaga() {
  yield takeEvery(
    actions.MEMBER_POST_NOTIFICATION,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(memberService.postNotification, model);
        if (response.status === 200) {
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.status === 500)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* getMemberTreeLevel() {
  yield takeEvery(
    actions.GET_MEMBER_TREE_LEVEL,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { code } = payload;
      try {
        var response = yield call(memberService.getMemberTreeLevel, code);
        if (response.status === 200) {
          const { data } = response.data;
          // yield put({
          //   type: actions.GET_MEMBER_TREE_LEVEL_SUCCESS,
          //   payload: { data }
          // });
          _cbSuccess(data, code);
        } else {
          let { message } = response.data;
          _cbError(message);
        }
      } catch (e) {
        // yield put({
        //   type: actions.GET_MEMBER_TREE_LEVEL_ERROR,
        // });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* getMembersHistory() {
  yield takeEvery(actions.GET_MEMBER_HISTORY_LEVEL, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(memberService.getListHistoryLevel, model);
      if (response.data.success === true) {
        const { data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_MEMBER_HISTORY_LEVEL_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        // _cbError(message);
      }
    } catch (e) {
      yield put({
        type: actions.GET_MEMBER_HISTORY_LEVEL_ERROR,
      });
      // if (e.response && e.response.data)
      //   yield _cbError(e.response.data.ErrorMessage);
      // else yield _cbError();
    }
  });
}
export function* getPackageSelectList() {
  yield takeEvery(actions.GET_PACKAGE_SELECT_LIST, function* ({ payload }) {
    try {
      var response = yield call(memberService.getPackageSelectList);
      if (response.data.success === true) {
        var { data } = response.data;
        yield put({
          type: actions.GET_PACKAGE_SELECT_LIST_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_PACKAGE_SELECT_LIST_ERROR });
    }
  });
}
//#endregion

export function* getLandingPageList() {
  yield takeEvery(actions.GET_LANDINGPAGE_LIST, function* ({ payload }) {
    const { id } = payload;

    try {
      var response = yield call(memberService.getLandingPageList, id);
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_LANDINGPAGE_LIST_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_LANDINGPAGE_LIST_ERROR });
    }
  });
}

export function* changeStatusLandingPage() {
  yield takeEvery(actions.CHANGE_STATUS_LANDINGPAGE, function* ({ payload }) {
    const { model } = payload;

    try {
      var response = yield call(memberService.changeStatusLandingPage, model);
      if (response.data.success === true) {
        var { data } = response.data;
        yield put({
          type: actions.CHANGE_STATUS_LANDINGPAGE_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_STATUS_LANDINGPAGE_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getMembersSaga),
    fork(getMemberDetailSaga),
    fork(createMemberSaga),
    fork(deleteMemberSaga),
    fork(updateMemberSaga),
    fork(getMemberPurchasedDocument),
    fork(getMemberExtention),
    fork(postAvtivate),
    fork(getRegisteredActivities),
    fork(resetPassword),
    fork(postMemberNotificationSaga),
    fork(getMemberTreeLevel),
    fork(getMembersHistory),
    fork(getPackageSelectList),
    fork(getLandingPageList),
    fork(changeStatusLandingPage),
  ]);
}
