const actions = {
    GET_BASIC_DOCUMENT: "GET_BASIC_DOCUMENT",
    GET_BASIC_DOCUMENT_SUCCESS: "GET_BASIC_DOCUMENT_SUCCESS",
    GET_BASIC_DOCUMENT_ERROR: "GET_BASIC_DOCUMENT_ERROR",
    
    getBasicDocument: () => ({
        type: actions.GET_BASIC_DOCUMENT,
    }),

    UPDATE_BASIC_DOCUMENT:"UPDATE_BASIC_DOCUMENT",
    UPDATE_BASIC_DOCUMENT_SUCCESS:"UPDATE_BASIC_DOCUMENT_SUCCESS",
    UPDATE_BASIC_DOCUMENT_ERROR:"UPDATE_BASIC_DOCUMENT_ERROR",

    updateBasicDocument: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_BASIC_DOCUMENT,
        payload: { model },
        cbSuccess, cbError
    }),
}
export default actions;