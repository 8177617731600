import actions from './actions';

const initState = {
  events: [],
  eventDetail: {},
  loading: false,
  totalItems: 0,
};

export default function eventReducer(state = initState, action) {
  switch (action.type) {

    //#region CRUD
    case actions.GET_EVENTS:
      return { ...state, loading: true, totalItems: 0 };
    case actions.GET_EVENTS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return { ...state, events: res, totalItems: totalItems, loading: false };
    }
    case actions.GET_EVENTS_ERROR:
      return { ...state, loading: false };

    case actions.GET_EVENT_DETAIL:
      return { ...state, loading: true };
    case actions.GET_EVENT_DETAIL_SUCCESS: {
      let { data } = action.payload;
      let res = data ? data : {};
      return { ...state, eventDetail: res, loading: false };
    }
    case actions.GET_EVENT_DETAIL_ERROR:
      return { ...state, loading: false };
    //#endregion

    default:
      return state;

  }
}
