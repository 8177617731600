import * as documentService from '@iso/services/document';
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import commonActions from '../common/actions';

//#region CRUD
export function* getDocuments() {
  yield takeEvery(actions.GET_DOCUMENTS, function* ({ payload }) {
    const { model } = payload;
    try {
      var response = yield call(documentService.getDocuments, model);
      if (response.data.success === true) {
        var data = response.data.data.data;
        var totalItems = response.data.data.total;
        yield put({
          type: actions.GET_DOCUMENTS_SUCCESS,
          payload: { data, totalItems }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_DOCUMENTS_ERROR,
      })
    }
  })
}

export function* deleteDocument() {
  yield takeEvery(actions.DELETE_DOCUMENT, function* ({ payload, cbSuccess, cbError }) {
    const { id } = payload;
    try {
      var response = yield call(documentService.deleteDocument, id);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* deleteDocumentFile() {
  yield takeEvery(actions.DELETE_DOCUMENT_FILE, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(documentService.deleteDocumentFile, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* getDocumentDetail() {
  yield takeEvery(actions.GET_DOCUMENT_DETAIL, function* ({ payload, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(documentService.getDocumentDetail, id);
      if (response.data.success === true) {
        var data = response.data.data;
        yield put({
          type: actions.GET_DOCUMENT_DETAIL_SUCCESS,
          payload: { data }
        })
      }
    } catch (e) {
      yield put({
        type: actions.GET_DOCUMENT_DETAIL_ERROR,
      });

      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}

export function* createDocument() {
  yield takeEvery(actions.CREATE_DOCUMENT, function* ({ payload, cbSuccess, cbError }) {
    const { model } = payload;
    try {
      var response = yield call(documentService.createDocument, model);
      if (response.data.success === true) {
        const { id } = response.data.data;
        yield cbSuccess(id);
      }
    } catch (e) {
      if (e.response && e.response?.data) {
        const { ErrorMessage } = e.response.data;
        yield cbError(ErrorMessage);
      }
      else
        yield cbError();
    }
  })
}

export function* createDocumentFiles() {
  yield takeEvery(actions.CREATE_DOCUMENT_FILES, function* ({ payload, _cbSuccess, _cbError, _onUploadProgress }) {
    const { model } = payload;
    try {
      var response = yield call(documentService.createDocumentFiles, model, _onUploadProgress);
      if (response.data?.success) {
        yield _cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response?.data) {
        const { ErrorMessage } = e.response.data;
        yield _cbError(ErrorMessage);
      }
      else
        yield _cbError();
    }
  })
}

export function* updateDocument() {
  yield takeEvery(actions.UPDATE_DOCUMENT, function* ({ payload, cbSuccess, cbError }) {
    const { id, model } = payload;
    try {
      var response = yield call(documentService.updateDocument, id, model);
      if (response.data.success === true) {
        yield cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield cbError(e.response.data.ErrorMessage);
      else
        yield cbError();
    }
  })
}

export function* downloadDocumentFileSaga() {
  yield takeEvery(
    actions.DOWNLOAD_DOCUMENT_FILE,
    function* ({ payload, _cbSuccess, _cbError }) {
      try {
        const { model } = payload;
        var response = yield call(documentService.downloadDocumentFileService, model);
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const type = response.data.type;
          const link = document.createElement("a");
          link.href = url;
          const fileName = `${model?.fileName}_${`${date}`}.${model?.fileType}`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.ErrorMessage);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getDocuments),
    fork(deleteDocument),
    fork(deleteDocumentFile),
    fork(getDocumentDetail),
    fork(createDocument),
    fork(createDocumentFiles),
    fork(updateDocument),
    fork(downloadDocumentFileSaga),
  ])
}
