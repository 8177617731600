export const Pages = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  CREATE_TOPIC_SENTENCE: "CREATE_TOPIC_SENTENCE",
};
export const Urls = {
  SENTENCE: "/dashboard/sentences",
  TOPIC: "/dashboard/topics",
};
export const UserModels = {
  status: [
    { text: "Hoạt động", value: true },
    { text: "Không hoạt động", value: false },
  ],
  userTypeSearch: [
    { text: "Quản trị viên", value: 1 },
    { text: "CRM", value: 2 },
    // { text: "Thành viên", value: 100 },
  ],
  userTypeAdd: [
    { text: "Quản trị viên", value: 1 },
    { text: "CRM", value: 2 },
  ]
};

export const CommonModels = {
  status: [
    { text: "Hoạt động", value: "true" },
    { text: "Không hoạt động", value: "false" },
  ],
  orderBy: [
    { text: "orderby_add_newest", value: 0 },
    { text: "orderby_add_oldest", value: 1 },
    { text: "orderby_name_az", value: 2 },
    { text: "orderby_name_za", value: 3 },
  ],
  sumOrder: {
    name_az: 0,
    name_za: 1,
    add_newest: 2,
    add_oldest: 3,
    update_newest: 4,
    update_oldest: 5,
    code_az: 6,
    code_za: 7,
    email_az: 8,
    email_za: 9,
  },
};

export const OrderBy = {
  livestream: {
    code_az: 0,
    code_za: 1,
    name_az: 2,
    name_za: 3,
    title_az: 4,
    title_za: 5,
    date_az: 6,
    date_za: 7,
    date_create_az: 8,
    date_create_za: 9,
    date_update_az: 10,
    date_update_za: 11,
  },
  schedule: {
    code_az: 0,
    code_za: 1,
    name_az: 2,
    name_za: 3,
    title_az: 4,
    title_za: 5,
    date_az: 6,
    date_za: 7,
    date_create_az: 8,
    date_create_za: 9,
    date_update_az: 10,
    date_update_za: 11,
  },
  document: {
    code_az: 0,
    code_za: 1,
    name_az: 2,
    name_za: 3,
    price_az: 4,
    price_za: 5,
    date_create_az: 6,
    date_create_za: 7,
    date_update_az: 8,
    date_update_za: 9,
  },
  event: {
    code_az: 0,
    code_za: 1,
    name_az: 2,
    name_za: 3,
    title_az: 4,
    title_za: 5,
    date_create_az: 6,
    date_create_za: 7,
    date_update_az: 8,
    date_update_za: 9,
  },
  lecturer: {
    code_az: 0,
    code_za: 1,
    name_az: 2,
    name_za: 3,
    date_create_az: 4,
    date_create_za: 5,
    date_update_az: 6,
    date_update_za: 7,
    date_seller_az: 8,
    date_seller_za: 9,
  },
  member: {
    code_az: 0,
    code_za: 1,
    name_az: 2,
    name_za: 3,
    joinDate_az: 4,
    joinDate_za: 5,
    expireDate_az: 6,
    expireDate_za: 7,
    activateDate_az: 8,
    activateDate_za: 9,
  },
  categories: {
    title_az: 0,
    title_za: 1,
    description_az: 2,
    description_za: 3,
    date_create_az: 4,
    date_create_za: 5,
    date_update_az: 6,
    date_update_za: 7,
  },
  staticPage: {
    title_az: 0,
    title_za: 1,
  },
  memberPurchased: {
    code_az: 0,
    code_za: 1,
    price_az: 2,
    price_za: 3,

  }
};

export const NotifyModels = {
  types: [
    { text: "Thay đổi mật khẩu", value: 1 },
    { text: "Đăng ký tài khoản", value: 2 },
    { text: "Thông báo chung", value: 0 },
  ],
};

export const Sorter = {
  code_az: "code_asc",
  code_za: "code_desc",
  title_az: "title_asc",
  title_za: "title_desc",
  full_name_az: "fullname_asc",
  full_name_za: "fullname_desc",
  name_az: "name_asc",
  name_za: "name_desc",
  email_az: "email_asc",
  email_za: "email_desc",
  phone_az: "phonenumber_asc",
  phone_za: "phonenumber_desc",
  added_date_az: "addeddate_asc",
  added_date_za: "addeddate_desc",
  updated_date_az: "updateddate_asc",
  updated_date_za: "updateddate_desc",
  due_date_az: "duedate_asc",
  due_date_za: "duedate_desc",
  price_az: "price_asc",
  price_za: "price_desc",
  joint_date_az: "jointdate_asc",
  joint_date_za: "jointdate_desc",
  expired_date_az: "expirationdate_asc",
  expired_date_za: "expirationdate_desc",
  activated_date_az: "activationdate_asc",
  activated_date_za: "activationdate_desc",
  registered_date_az: "registerdate_asc",
  registered_date_za: "registerdate_desc",
  payment_date_az: "paymentdate_asc",
  payment_date_za: "paymentdate_desc",
  revenue_az: "revenue_asc",
  revenue_za: "revenue_desc",
  ordered_date_az: "orderDate_asc",
  ordered_date_za: "orderDate_desc",
  username_az: "userName_asc",
  username_za: "userName_desc",
  total_amount_az: "totalamount_asc",
  total_amount_za: "totalamount_desc",
}

export const Roles = {
  user: {
    create: "user_management_create",
    view: "user_management_view",
    edit: "user_management_update",
    delete: "user_management_delete",
    list: "user_management_list",
  },
  roleGroup: {
    create: "group_permission_management_create",
    view: "group_permission_management_view",
    edit: "group_permission_management_update",
    delete: "group_permission_management_delete",
    list: "group_permission_management_list",
  },
  team: {
    create: "team_management_create",
    view: "team_management_view",
    edit: "team_management_update",
    delete: "team_management_delete",
    list: "team_management_list",
  },
  notification: {
    management: "notification_management",
    list: "notification_list"
  },
  teachingSchedule: {
    create: "teaching_schedule_management_create",
    view: "teaching_schedule_management_view",
    edit: "teaching_schedule_management_update",
    delete: "teaching_schedule_management_delete",
    list: "teaching_schedule_management_list",
  },
  livestream: {
    create: "livestream_management_create",
    view: "livestream_management_view",
    edit: "livestream_management_update",
    delete: "livestream_management_delete",
    list: "livestream_management_list",
  },
  event: {
    create: "event_management_create",
    view: "event_management_view",
    edit: "event_management_update",
    delete: "event_management_delete",
    list: "event_management_list",
  },
  document: {
    create: "document_management_create",
    view: "document_management_view",
    edit: "document_management_update",
    delete: "document_management_delete",
    list: "document_management_list",
  },
  lecturer: {
    create: "lecturer_management_create",
    view: "lecturer_management_view",
    edit: "lecturer_management_update",
    delete: "lecturer_management_delete",
    list: "lecturer_management_list",
  },
  member: {
    create: "membership_management_create",
    view: "membership_management_view",
    edit: "membership_management_update",
    delete: "membership_management_delete",
    list: "membership_management_list",
  },
  documentCategory: {
    create: "document_category_management_create",
    view: "document_category_management_view",
    edit: "document_category_management_edit",
    delete: "document_category_management_delete",
    list: "document_category_management_list",
  },
  staticPage: {
    create: "static_page_management_create",
    view: "static_page_management_view",
    edit: "static_page_management_edit",
    delete: "static_page_management_delete",
    list: "static_page_management_list",
  },
  banner: {
    create: "banner_management_create",
    view: "banner_management_view",
    edit: "banner_management_edit",
    delete: "banner_management_delete",
    list: "banner_management_list",
  },
  packages: {
    create: "package_management_create",
    view: "package_management_view",
    edit: "package_management_edit",
    delete: "package_management_delete",
    list: "package_management_list",
  },
  paymentHistory: {
    create: "payment_history_management_create",
    view: "payment_history_management_detail",
    edit: "payment_history_management_update",
    delete: "payment_history_management_delete",
    list: "payment_history_management_list",
  },
  tree: {
    create: "payment_history_management_create",
    view: "payment_history_management_detail",
    edit: "payment_history_management_update",
    delete: "payment_history_management_delete",
    list: "payment_history_management_list",
  }
};

export const DocumentType = [
  { name: "Cơ bản", value: 1 },
  { name: "VIP", value: 2 },
];

export const BasicDetail = [
  { name: "Sơ cấp", value: 1 },
  { name: "Trung cấp", value: 2 },
  { name: "Cao cấp", value: 3 },
  { name: "Người mới", value: 4 },
  { name: "Chuyên đề", value: 5 },
  { name: "Thương hiệu", value: 100 },
  { name: "Sản phẩm", value: 101 },
  { name: "Sức khỏe", value: 102 },
];

export const AddressModels = {
  type: {
    PROVINCE: 1,
    DISTRICT: 2,
    WARD: 3,
  },
};
export const Setting = {
  unit: {
    METTER: 1,
    HOUR: 2,
    BOOL: 3,
    NUMBER: 4,
    TEXT: 5,
  },
};

export const CurrentStatus = [
  { name: "Chưa diễn ra", value: "false" },
  { name: "Đã diễn ra", value: "true" },
];
export const FileType = [
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".doc", ".docx",
];


export const DateType = [
  { value: 1, name: "Ngày tham gia" },
  { value: 2, name: "Ngày kích hoạt" },
];
export const DateTypeMember = [
  { value: 1, name: "Ngày tham gia" },
  { value: 2, name: "Ngày hết hạn" },
]

export const RootPaths = {
  Users: "users",
  Roles: "roles",
  Events: "events",
  TeachingSchedules: "teaching-schedules",
  Livestreams: "livestreams",
  Lecturers: "lecturers",
  Documents: "documents",
  Dashboard: "dashboard",
  Members: "members",
  Banners: "banner",
  DocumentBanners: "document-banners",
}

export const Months = [
  { value: 1, name: "Tháng 1" },
  { value: 2, name: "Tháng 2" },
  { value: 3, name: "Tháng 3" },
  { value: 4, name: "Tháng 4" },
  { value: 5, name: "Tháng 5" },
  { value: 6, name: "Tháng 6" },
  { value: 7, name: "Tháng 7" },
  { value: 8, name: "Tháng 8" },
  { value: 9, name: "Tháng 9" },
  { value: 10, name: "Tháng 10" },
  { value: 11, name: "Tháng 11" },
  { value: 12, name: "Tháng 12" },
];

export const ListActivities = [
  { value: RootPaths.TeachingSchedules, name: "Lịch đào tạo" },
  { value: RootPaths.Livestreams, name: "Livestream" },
  { value: RootPaths.Events, name: "Khuyến mãi" },
];

export const FileExcepted = {
  file_list: new Map([
    [RootPaths.TeachingSchedules, [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".doc", ".docx",
      "image/png", "image/jpeg", "image/*", "image/jpg"
    ]],
    [RootPaths.Livestreams, ["image/png", "image/jpeg", "image/*", "image/jpg"]],
    [RootPaths.Lecturers, ["image/png", "image/jpeg", "image/*", "image/jpg"]],
    [RootPaths.Events, ["image/png", "image/jpeg", "image/*", "image/jpg"]],
    [RootPaths.Documents, [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".doc", ".docx",
      "image/png", "image/jpeg", "image/*", "image/jpg",
      ".pptx", ".ppt",
      "video/mp4",
    ]],
    [RootPaths.Members, ["image/png", "image/jpeg", "image/*", "image/jpg"]],
    [RootPaths.Banners, ["image/png", "image/jpeg", "image/*", "image/jpg"]],
    [RootPaths.DocumentBanners, ["video/mp4", "image/png", "image/jpeg", "image/*", "image/jpg"]],
  ]),

  getFileExcepted(type) {
    return FileExcepted.file_list.get(type);
  }
}

export const ImageType = ["image/png", "image/jpeg", "image/*", "image/jpg"];

export const PaymentMethods = [
  { value: "0", name: "Offline" },
  { value: "1", name: "Momo" },
  { value: "2", name: "Zalo pay" },
  { value: "3", name: "Chuyển khoản" },
  { value: "4", name: "One pay" },
];

export const PaymentStatus = [
  { value: 0, name: "Chưa thanh toán" },
  { value: 1, name: "Thành công" },
  { value: 2, name: "Thất bại" },
];

export const PaymentType = [
  { value: "0", name: "Kích hoạt tài khoản" },
  { value: "1", name: "Gia hạn tài khoản" },
];

export const RoleLevelCompany = [
  { value: "1", name: "1" },
  { value: "2", name: "2" },
  { value: "3", name: "3" },
  { value: "4", name: "4" },
  { value: "5", name: "5" },
  { value: '6', name: "6" },
  { value: '7', name: "7" },
  { value: '8', name: "8" },
  { value: '9', name: "9" },
  { value: '10', name: "10" },

];

export const RoleLevelSystem = [
  { value: '-1', name: "Không xác định" },
  { value: '0', name: "Người mới" },
  { value: '10', name: "CM" },
  { value: '20', name: "KP" },
  { value: '30', name: "KLD" },
  { value: '40', name: "OLD" },
  { value: '50', name: "ELD 1" },
  { value: '60', name: "ELD 2" },
  { value: '70', name: "ELD 3" },
  { value: '80', name: "ELD 4" },
  { value: '90', name: "ELD 5" },
]
