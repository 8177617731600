const actions = {
    GET_PACKAGE: "GET_PACKAGE",
    GET_PACKAGE_SUCCESS: "GET_PACKAGE_SUCCESS",
    GET_PACKAGE_ERROR: "GET_PACKAGE_ERROR",

    getPackage: (model) => ({
        type: actions.GET_PACKAGE,
        payload: { model },
    }),
    GET_PACKAGE_DETAIL: "GET_PACKAGE_DETAIL",
    GET_PACKAGE_DETAIL_SUCCESS: "GET_PACKAGE_DETAIL_SUCCESS",
    GET_PACKAGE_DETAIL_ERROR: "GET_PACKAGE_DETAIL_ERROR",

    getPackageDetail: (id) => ({
        type: actions.GET_PACKAGE_DETAIL,
        payload: { id }
    }),

    CREATE_PAKAGE: "CREATE_PAKAGE",

    cretatePakage: (model, cbSuccess, cbError) => ({
        type: actions.CREATE_PAKAGE,
        payload: { model },
        cbSuccess, cbError
    }),
    DELETE_PACKAGE: "DELETE_PACKAGE",

    deletePackage: (id, cbSuccess, cbError) => ({
        type: actions.DELETE_PACKAGE,
        payload: { id },
        cbSuccess, cbError
    }),

    UPDATE_PACKAGE: "UPDATE_PACKAGE",
    updatePackage: (model, cbSuccess, cbError) => ({
        type: actions.UPDATE_PACKAGE,
        payload: { model },
        cbSuccess, cbError
    }),
}
export default actions;