import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import * as imageService from "@iso/services/image";

export function* getImage() {
  yield takeEvery(actions.GET_IMAGE, function* ({ payload }) {
    const { model } = payload;

    try {
      var response = yield call(imageService.getImage, model);
     
      if (response.data.success === true) {
        var { data } = response.data.data;
        yield put({
          type: actions.GET_IMAGE_SUCCESS,
          payload: { data },
        });
      }
    } catch (e) {
      yield put({ type: actions.GET_IMAGE_ERROR });
    }
  });
}

export function* createImage() {
  yield takeEvery(
    actions.CREATE_IMAGE,
    function* ({ payload, cbSuccess, cbError }) {
      const { body } = payload;
      try {
        var response = yield call(imageService.createImage, body);
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export function* deleteImage() {
  yield takeEvery(
    actions.DELETE_IMAGE,
    function* ({ payload, cbSuccess, cbError }) {
      const { id } = payload;
      try {
        var response = yield call(imageService.deleteImage, id);
        if (response.data.success === true) {
          yield cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield cbError(e.response.data.ErrorMessage);
        else yield cbError();
      }
    }
  );
}
export default function* rootSaga() {
  yield all([fork(getImage), fork(createImage), fork(deleteImage)]);
}
