const actions = {
  GET_MEMBERS: "GET_MEMBERS",
  GET_MEMBERS_SUCCESS: "GET_MEMBERS_SUCCESS",
  GET_MEMBERS_ERROR: "GET_MEMBERS_ERROR",

  getMembers: (model, _cbError) => ({
    type: actions.GET_MEMBERS,
    payload: { model },
    _cbError,
  }),

  GET_MEMBER_DETAIL: "GET_MEMBER_DETAIL",
  GET_MEMBER_DETAIL_SUCCESS: "GET_MEMBER_DETAIL_SUCCESS",
  GET_MEMBER_DETAIL_ERROR: "GET_MEMBER_DETAIL_ERROR",

  getMemberDetail: (id, _cbError) => ({
    type: actions.GET_MEMBER_DETAIL,
    payload: { id },
    _cbError,
  }),

  //#region CURD
  CREATE_MEMBER: "CREATE_MEMBER",
  createMember: (body, _cbSuccess, _cbError) => ({
    type: actions.CREATE_MEMBER,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),

  DELETE_MEMBER: "DELETE_MEMBER",
  deleteMember: (id, _cbSuccess, _cbError) => ({
    type: actions.DELETE_MEMBER,
    payload: { id },
    _cbSuccess,
    _cbError,
  }),

  UPDATE_MEMBER: "UPDATE_MEMBER",
  updateMember: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_MEMBER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  GET_MEMBER_PURCHASED_DOCUMENT: "GET_MEMBER_PURCHASED_DOCUMENT",
  GET_MEMBER_PURCHASED_DOCUMENT_SUCCESS:
    "GET_MEMBER_PURCHASED_DOCUMENT_SUCCESS",
  GET_MEMBER_PURCHASED_DOCUMENT_ERROR: "GET_MEMBER_PURCHASED_DOCUMENT_ERROR",

  getMemberPurchasedDocument: (model) => ({
    type: actions.GET_MEMBER_PURCHASED_DOCUMENT,
    payload: { model },
  }),
  GET_MEMBER_EXTENSION: "GET_MEMBER_EXTENTION",
  GET_MEMBER_EXTENSION_SUCCESS: "GET_MEMBER_EXTENSION_SUCCESS",
  GET_MEMBER_EXTENSION_ERROR: "GET_MEMBER_EXTENSION_ERROR",

  getMemberExtension: (model) => ({
    type: actions.GET_MEMBER_EXTENSION,
    payload: { model },
  }),
  POST_ACTIVATE: "POST_ACTIVATE",
  POST_ACTIVATE__SUCCESS: "POST_ACTIVATE_SUCCESS",
  POST_ACTIVATE_ERROR: "POST_ACTIVATE_ERROR",

  postActivate: (model, _cbSuccess, _cbError) => ({
    type: actions.POST_ACTIVATE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  GET_REGISTERED_ACTIVITIES: "GET_REGISTERED_ACTIVITIES",
  GET_REGISTERED_ACTIVITIES_SUCCESS: "GET_REGISTERED_ACTIVITIES_SUCCESS",
  GET_REGISTERED_ACTIVITIES_ERROR: "GET_REGISTERED_ACTIVITIES_ERROR",

  getRegisteredActivities: (id, model) => ({
    type: actions.GET_REGISTERED_ACTIVITIES,
    payload: { model, id },
  }),
  //#endregion
  MEMBER_RESET_PASSWORD: "MEMBER_RESET_PASSWORD",
  resetPasswordMember: (model, cbSuccess, cbError) => ({
    type: actions.MEMBER_RESET_PASSWORD,
    payload: { model },
    cbSuccess,
    cbError,
  }),
  MEMBER_POST_NOTIFICATION: "MEMBER_POST_NOTIFICATION",
  postNotification: (model, _cbSuccess, _cbError) => ({
    type: actions.MEMBER_POST_NOTIFICATION,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  GET_MEMBER_TREE_LEVEL: "GET_MEMBER_TREE_LEVEL",
  GET_MEMBER_TREE_LEVEL_SUCCESS: "GET_MEMBER_TREE_LEVEL_SUCCESS",
  GET_MEMBER_TREE_LEVEL_ERROR: "GET_MEMBER_TREE_LEVEL_ERROR",
  getMemberTreeLevel: (code) => ({
    type: actions.GET_MEMBER_TREE_LEVEL,
    payload: { code },
  }),

  GET_MEMBER_HISTORY_LEVEL: "GET_MEMBER_HISTORY_LEVEL",
  GET_MEMBER_HISTORY_LEVEL_SUCCESS: "GET_MEMBER_HISTORY_LEVEL_SUCCESS",
  GET_MEMBER_HISTORY_LEVEL_ERROR: "GET_MEMBER_HISTORY_LEVEL_ERROR",

  getMemberHistoryLevel: (model) => ({
    type: actions.GET_MEMBER_HISTORY_LEVEL,
    payload: { model },
  }),

  GET_PACKAGE_SELECT_LIST: "GET_PACKAGE_SELECT_LIST",
  GET_PACKAGE_SELECT_LIST_SUCCESS: "GET_PACKAGE_SELECT_LIST_SUCCESS",
  GET_PACKAGE_SELECT_LIST_ERROR: "GET_PACKAGE_SELECT_LIST_ERROR",
  getPackageSelectList: () => ({
    type: actions.GET_PACKAGE_SELECT_LIST,
  }),

  GET_LANDINGPAGE_LIST: "GET_LANDINGPAGE_LIST",
  GET_LANDINGPAGE_LIST_SUCCESS: "GET_LANDINGPAGE_LIST_SUCCESS",
  GET_LANDINGPAGE_LIST_ERROR: "GET_LANDINGPAGE_LIST_ERROR",
  getLadingPageList: (id) => ({
    type: actions.GET_LANDINGPAGE_LIST,
    payload: { id },
  }),

  CHANGE_STATUS_LANDINGPAGE: "CHANGE_STATUS_LANDINGPAGE",
  CHANGE_STATUS_LANDINGPAGE_SUCCESS: "CHANGE_STATUS_LANDINGPAGE_SUCCESS",
  CHANGE_STATUS_LANDINGPAGE_ERROR: "CHANGE_STATUS_LANDINGPAGE_ERROR",
  changeStatusLandingPage: (model) => ({
    type: actions.CHANGE_STATUS_LANDINGPAGE,
    payload: { model },
  }),
};

export default actions;
