import { ApiRouters } from '@iso/utils/apiRouters';
import { api } from '@iso/utils/axios.configs';

export const getCategorySelectboxService = (model) => {
  var url = `${ApiRouters.CATEGORY}/SelectList`;
  return api.get(url, { params: model });
}
export const getCategoryService = (body) => {
  var url = ApiRouters.CATEGORY;
  return api.get(url, { params: body });
}
export const createCategoryService = body => {
  var url = ApiRouters.CATEGORY;
  return api.post(url, body);
}
export const deleteCategoryService = id => {
  var url = `${ApiRouters.CATEGORY}/${id}`;
  return api.delete(url);
};
export const getCategoryDetailService = id => {
  var url = `${ApiRouters.CATEGORY}/${id}`;
  return api.get(url);
};
export const updateCategoryService = body => {
  var url = `${ApiRouters.CATEGORY}/${body.id}`;
  return api.put(url, body);
};