import Account from "@iso/redux/account/reducer";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Banner from "@iso/redux/banner/reducer";
import Image from "@iso/redux/image/reducer";
import Categories from "@iso/redux/categories/reducer";
import Common from "@iso/redux/common/reducer";
import Document from "@iso/redux/document/reducer";
import Event from "@iso/redux/event/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import Lecturers from "@iso/redux/lecturers/reducer";
import Livestream from "@iso/redux/livestream/reducer";
import Members from "@iso/redux/members/reducer";
import Notification from "@iso/redux/notifications/reducer";
import Payments from "@iso/redux/payments/reducer";
import Role from "@iso/redux/roles/reducer";
import StaticPage from "@iso/redux/staticPage/reducer";
import TeachingSchedule from "@iso/redux/teachingSchedule/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import User from "@iso/redux/users/reducer";
import Package from "@iso/redux/package/reducer";
import BasicDocument from "@iso/redux/basicDocument/reducer";

import { combineReducers } from "redux";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Account,
  Common,
  User,
  Role,
  Notification,
  TeachingSchedule,
  Livestream,
  Event,
  Document,
  Lecturers,
  Categories,
  Members,
  StaticPage,
  Image,
  Banner,
  Payments,
  Package,
  BasicDocument,
});
