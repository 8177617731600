import { ApiRouters } from '@iso/utils/apiRouters';
import { api, apiUpload } from '@iso/utils/axios.configs';
import axios from "axios";

export const getEvents = body => {
  var url = ApiRouters.EVENT;
  return api.get(url, { params: body });
};
export const deleteEvent = id => {
  var url = `${ApiRouters.EVENT}/${id}`;
  return api.delete(url);
};
export const getEventDetail = id => {
  var url = `${ApiRouters.EVENT}/${id}`;
  return api.get(url);
};
export const createEvent = body => {
  var url = ApiRouters.EVENT;
  return api.post(url, body);
};
export const createEventBanner = model => {
  var url = `${ApiRouters.EVENT}/${model.id}/Files`;
  return axios(apiUpload(url, 'post', model.body));
};
export const updateEvent = (id, body) => {
  var url = `${ApiRouters.EVENT}/${id}`;
  return api.put(url, body);
};