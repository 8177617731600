import * as paymentService from '@iso/services/payments';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import * as moment from 'moment';

//#region GET LIST
export function* getDocumentsOrderedSaga() {
  yield takeEvery(actions.GET_DOCUMENTS_ORDERED, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(paymentService.getDocumentsOrderedService, model);
      if (response.data.success === true) {
        const { orders, totalRevenue } = response.data.data;
        yield put({
          type: actions.GET_DOCUMENTS_ORDERED_SUCCESS,
          payload: { orders, totalRevenue }
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_DOCUMENTS_ORDERED_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  })
}

export function* getDocumentOrderedDetailSaga() {
  yield takeEvery(actions.GET_DOCUMENT_ORDERED_DETAIL, function* ({ payload, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(paymentService.getDocumentOrderedDetailService, id);
      if (response.data.success === true) {
        const { data } = response.data;
        yield put({
          type: actions.GET_DOCUMENT_ORDERED_DETAIL_SUCCESS,
          payload: { data }
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_DOCUMENT_ORDERED_DETAIL_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  })
}

export function* getAccountActivatedSaga() {
  yield takeEvery(actions.GET_ACCOUNTS_ACTIVATED, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(paymentService.getAccountsActivatedService, model);
      if (response.data.success === true) {
        const { activations, totalRevenue } = response.data.data;
        yield put({
          type: actions.GET_ACCOUNTS_ACTIVATED_SUCCESS,
          payload: { activations, totalRevenue }
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_ACCOUNTS_ACTIVATED_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  })
}

export function* getAccountActivatedDetailSaga() {
  yield takeEvery(actions.GET_ACCOUNT_ACTIVATED_DETAIL, function* ({ payload, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(paymentService.getAccountActivatedDetailService, id);
      if (response.data.success === true) {
        const { data } = response.data;
        yield put({
          type: actions.GET_ACCOUNT_ACTIVATED_DETAIL_SUCCESS,
          payload: { data }
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_ACCOUNT_ACTIVATED_DETAIL_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else yield _cbError();
    }
  })
}
export function* postMemberShipActivation() {
  yield takeEvery(actions.POST_MEMBERSHIPACTIVATION_PAYMENT, function* ({ payload, _cbSuccess, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(paymentService.postAccountActivatedDetailService, id);
      if (response.data.success === true) {
        yield _cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}
export function* postDocumentOrderPayment() {
  yield takeEvery(actions.POST_DOCUMENT_ORDER_PAYMENT, function* ({ payload, _cbSuccess, _cbError }) {
    const { id } = payload;
    try {
      var response = yield call(paymentService.postDocumentOrderService, id);
      if (response.data.success === true) {
        yield _cbSuccess();
      }
    } catch (e) {
      if (e.response && e.response.data)
        yield _cbError(e.response.data.ErrorMessage);
      else
        yield _cbError();
    }
  })
}
export function* exportPaymentDocumentSaga() {
  yield takeEvery(actions.EXPORT_DOCUMENT_PAYMENT, function* ({ payload }) {
    const { model } = payload
    try {
      var response = yield call(paymentService.exportDocumentPayments, model);
      if (response.data) {
        let date = moment(new Date()).format("DD_MM_YYYY");
        const fileName = `DocumentPayment_${date}.xlsx`;
        yield exportSuccess(fileName, response.data);
      }
    } catch (e) {
      yield put({ type: actions.EXPORT_ERROR })
    }
  })
}
export function* exportPaymentMembershipActivationSaga() {
  yield takeEvery(actions.EXPORT_MEMBERSHIPACTIVATION_PAYMENT, function* ({ payload }) {
    const { model } = payload
    try {
      var response = yield call(paymentService.exportMembershipActivationPayment, model);
      if (response.data) {
        let date = moment(new Date()).format("DD_MM_YYYY");
        const fileName = `DocumentPayment_${date}.xlsx`;
        yield exportSuccess(fileName, response.data);
      }
    } catch (e) {
      yield put({ type: actions.EXPORT_ERROR })
    }
  })
}
export function* postMemberShipActivationRejectSaga() {
  yield takeEvery(
    actions.POST_MEMBERSHIPACTIVATION_PAYMENT_REJECT,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { id, model } = payload;
      try {
        var response = yield call(paymentService.postAccountActivatedDetailRejectService, id, model);
        if (response.data.success === true) {
          // const { id } = response.data.data;
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
export function* postDocumentOrderPaymentReject() {
  yield takeEvery(
    actions.POST_DOCUMENT_ORDER_PAYMENT_REJECT,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { id, model } = payload;
      try {
        var response = yield call(paymentService.postDocumentOrderRejectService, id, model);
        if (response.data.success === true) {
          // const { id } = response.data.data;
          yield _cbSuccess();
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.ErrorMessage);
        else yield _cbError();
      }
    }
  );
}
function* exportSuccess(fileName, data) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  yield put({ type: actions.EXPORT_ERROR });
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getDocumentsOrderedSaga),
    fork(getAccountActivatedSaga),
    fork(getDocumentOrderedDetailSaga),
    fork(getAccountActivatedDetailSaga),
    fork(exportPaymentDocumentSaga),
    fork(exportPaymentMembershipActivationSaga),
    fork(postMemberShipActivation),
    fork(postDocumentOrderPayment),
    fork(postMemberShipActivationRejectSaga),
    fork(postDocumentOrderPaymentReject)
  ])
}