import actions from './actions';

const initState = {
    packages: [],
    totalItems: 0,
    loading: false,
    packageDetail: []
};

export default function userReducer(state = initState, action) {
    switch (action.type) {

        //#region CRUD
        case actions.GET_PACKAGE:
            return { ...state, loading: true, totalItems: 0 };
        case actions.GET_PACKAGE_SUCCESS: {
            let { data, totalItems } = action.payload;
            let res = data ? data : [];
            return { ...state, packages: res, totalItems: totalItems, loading: false };
        }
        case actions.GET_PACKAGE_ERROR:
            return { ...state, loading: false };
        case actions.GET_PACKAGE_DETAIL:
            return { ...state, loading: true };
        case actions.GET_PACKAGE_DETAIL_SUCCESS: {
            let { data } = action.payload;
            let res = data ? data : {};
            return { ...state, packageDetail: res, loading: false };
        }
        case actions.GET_PACKAGE_DETAIL_ERROR:
            return { ...state, loading: false };
        //#endregion
        default:
            return state;
    }
}
